<template>
  <div>
    <Body :title="$gettext(`My Portfolios`)" :fill="true" v-if="me&&me.portfolios&&me.portfolios.length" class="MyPortfoliosAll">
      <ul>
        <li v-for="(portfolioslist, org) in organisations" :key="org">
          <details open>
            <summary>{{ org }}</summary>
            <ul>
              <li v-for="portfolio of portfolioslist" :key="portfolio._id">
                <details open>
                  <summary><router-link :to="`/portfolio/${portfolio.identifier}`">{{ portfolio.name }} ({{ portfolio.identifier }})</router-link></summary>
                  <ul>
                    <template v-if="portfolio.reports.length">
                      <li v-for="report of portfolio.reports" :key="report._id">
                        <details>
                          <summary><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`">{{ report.title }} ({{ report.identifier }})</router-link></summary>
                          <dl class="productInfo">
                            <div>
                              <dt>URL:</dt>
                              <dd v-if="report.productUrl!=''">{{ report.productUrl }}</dd>
                              <dd v-else>None given</dd>
                            </div>
                            <div>
                              <dt>Risk:</dt>
                              <dd>{{ riskString(report.productRisk) }}</dd>
                            </div>
                            <div>
                              <dt>Disproportionate Burden:</dt>
                              <dd>{{ disproportionateBurdenString(report.disproportionateBurden) }}</dd>
                            </div>
                            <div>
                              <dt>Stated Compliance:</dt>
                              <dd>{{ accessibilityStatementString(report.accessibilityStatement) }}</dd>
                            </div>
                            <div>
                              <dt>Actual Compliance:</dt>
                              <dd>{{ actualComplianceString(report.currentVersion.isCompliant, report.currentVersion.hasOpenIssues, report.currentVersion.progress) }}</dd>
                            </div>
                            <div>
                              <dt>Progress: </dt>
                              <dd><ProgressBar :id="`reportprogress-${report._id}`" :label="$gettext( '%{projectString} Progress', { projectString: $hugrConfig.projectStringCap } )" :hideLabel="true" :value="report.currentVersion.progress"/></dd>
                            </div>
                          </dl>
                        </details>
                      </li>
                    </template>
                    <li v-else>{{ $gettext( 'No %{projectString}s', { projectString: $hugrConfig.projectString } ) }}</li>
                  </ul>
                </details>
              </li>
            </ul>
          </details>
        </li>
      </ul>
    </Body>

    <Body :title="$apollo.queries.me.loading ? $gettext('Loading') : $gettext('No portfolios')" :fill="true" v-else>
      <div v-if="$apollo.queries.me.loading">
        <Loader />
      </div>
      <div v-else>
        {{$gettext("You don't have any portfolios that you are a principal of or you do not belong to any teams which are assigned to a portfolio.")}}
      </div>
    </Body>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

import ProgressBar from '@/components/UI/ProgressBar';

export default {
  name: 'MyPortfoliosAllView',
  data() {
    return {
      me: false,
      portfolios: false,
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'My Portfolios All',
      },
    } );
  },
  apollo: {
    me: {
      query: gql`
        {
          me: WhoAmI {
            _id,
            email,
            name,
            aclevel,
            mfaEnabled,
            portfolios {
              _id
              identifier
              name,
              customer {
                _id,
                name
              }
              reports {
                _id
                identifier
                title,
                productUrl,

                productRisk,
                disproportionateBurden,
                accessibilityStatement,

                currentVersion {
                  _id
                  isCompliant
                  hasOpenIssues

                  progress
                }
              }
              teams {
                _id
              }
            }
          }
        }
      `,
    },
  },
  methods: {
    riskString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'low';

          break;
        }
        case '1': {
          ret = 'medium';

          break;
        }
        case '2': {
          ret = 'high';

          break;
        }
      }

      return this.$gettext( ret );
    },
    disproportionateBurdenString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'none';

          break;
        }
        case '1': {
          ret = 'being considered';

          break;
        }
        case '2': {
          ret = 'made';

          break;
        }
        case '3': {
          ret = 'expired';

          break;
        }
      }

      return this.$gettext( ret );
    },
    accessibilityStatementString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'Unknown';

          break;
        }
        case '1': {
          ret = 'partially - mostly unmet';

          break;
        }
        case '2': {
          ret = 'partally - mostly met';

          break;
        }
        case '3': {
          ret = 'met';

          break;
        }
        case '4': {
          ret = 'exceeded';

          break;
        }
      }

      return this.$gettext( ret );
    },
    actualComplianceString( isCompliant, hasOpenIssues, progress ) {
      if( progress == 0 ) return 'Untested';

      if( isCompliant && progress < 100 ) return 'Partially tested';

      let ret = '';
      if( isCompliant ) {
        ret = 'Is Compliant';
      } else {
        ret = 'Not Compliant';
      }

      if( isCompliant && hasOpenIssues ) ret += ' with advisories';

      if( progress < 100 ) ret += ', Partially tested';
      
      return ret;
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    organisations() {
      const ret = {};
      for( const portfolio of this.me.portfolios ) {
        if( typeof ret[portfolio.customer.name] == 'undefined' ) {
          ret[portfolio.customer.name] = [];
        }
        ret[portfolio.customer.name].push( portfolio );
      }

      return ret;
    },
  },
  components: {
    // Comments,
    // ReportModal,
    // CsvPortfolioModal,

    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

.MyPortfoliosAll {
  ul {
    list-style: none;
    padding-left: 18px;
    a {
      text-decoration: none;
    }
  }
}

.productInfo {
  border-left: 4px solid $hugr-colours-grey;
  margin-left: 18px;
  padding-left: 8px;
  > div {
    dt, dd { display: inline; }
    dd {
      margin-left: 10px;
    }
  }
}
</style>
