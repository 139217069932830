<template>
  <div ref="summary" :class="['GroupSummary', `_offset-up-${offsetUp}`]">
    <div class="GroupSummary_Main">
      <div class="GroupSummary_Main_Header">
        <h3 class="GroupSummary_Main_Header_Title">
          {{ type.charAt( 0 ).toUpperCase() }}{{ type.substring( 1 ) }} {{ typeEnding }}
        </h3>
        <!-- <Button class="GroupSummary_Main_Header_ContextButton" v-if="Array.isArray(contextItems) && enabledContextItems.length > 0" type="icon" :icon="['solid', 'ellipsis-vertical']" aria-controls="contextMenu" :aria-expanded="contextMenuOpen?'true':'false'" ref="contextMenuBtnRef" @click="toggleContextMenu" @keyup.space.prevent="toggleContextMenu" @keyup.esc.prevent="toggleContextMenuOff">{{ $gettext(`More Options`) }}</Button> -->
        
        <ButtonSet label="Options" v-if="Array.isArray(contextItems) && enabledContextItems.length > 0" :dropdown="true" type="icon" :icon="['solid', 'ellipsis-vertical']">
          <template v-for="(contextItem, i) in contextItems" :key="`contextMenu-item-${i}`">
            <template v-if="contextItem.enabled">
              <LinkButton v-if="contextItem.routerTo" :to="contextItem.routerTo" size="small" type="transparent" @click="$emit(contextItem.emit)" :icon="contextItem.icon">{{ $gettext(contextItem.name) }}</LinkButton>
              <Button v-else size="small" type="transparent" :ref="`contextMenu-item-btn-${i}`" @click="$emit(contextItem.emit)" :icon="contextItem.icon">{{ $gettext(contextItem.name) }}</Button>
            </template>
          </template>
        </ButtonSet>
      </div>

      <dl class="GroupSummary_Main_Details" v-if="!loading">
        <template v-for="( value, key ) of details" :key="key">
          <div class="GroupSummary_Main_Details_Item" v-if="value!=''">
            <div class="GroupSummary_Main_Details_Item_Left">
            <dt>{{ key }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <Link v-if="value.indexOf('http')>=0" :external="true" :href="value" target="_blank" :title="value">{{ value }}</Link>
              <template v-else>{{ value }}</template>
            </dd>
            </div>
          </div>
        </template>
      </dl>

      <div class="GroupSummary_Main_Screenshot" v-if="showScreenshots && screenshot && screenshot != '' && !loading">
        <Screenshot :sID="screenshot._id" alt="Screenshot of page" :lightbox="true"/>
      </div>

      <div class="GroupSummary_Main_Statement _separator" v-if="!accessibilityStatementDisabled && !loading">
        <div class="GroupSummary_Main_Statement_Title">
          <h4>Accessibility statement:</h4>
        </div>
        <div class="GroupSummary_Main_Statement_Contents">
          <LinkButton direction="vertical" size="small" :to="accessibilityStatementLink">{{$gettext('View statement')}}</LinkButton>
        </div>
      </div>

      <div class="GroupSummary_Main_Compliance _separator" v-if="complianceEnabled && !loading">
        <div class="GroupSummary_Main_Compliance_Title">
          <h4>{{ `${type[0].toUpperCase()}${type.slice( 1 )}` }} compliance:</h4>
          <p>(Based on WCAG {{ wcagVersion }} {{ wcagLevel }})</p>
        </div>
        <div class="GroupSummary_Main_Compliance_Contents">
          <CompliancePill :progress="progress" :isCompliant="isCompliant" :issues="issues.length"/>
        </div>
      </div>

      <div class="GroupSummary_Main_Chart _separator" v-if="pieEnabled && issues.length && !loading">
        <h4 class="GroupSummary_Main_Chart_Title">Issues by severity:</h4>
        <Pie class="GroupSummary_Main_Chart_Pie" size="small" background="lightgrey" :data="pieData" :centerKey="pieKey" />
        <Button v-if="csvExport && type == 'page'" direction="vertical" @click="$emit('csvExport')" :icon="['solid', 'file-lines']">
            {{$gettext('Export issues on this page (CSV)')}}
        </Button>
      </div>

      <div v-if="loading">
          <Loader />
      </div>
    </div>

    <div class="GroupSummary_Others" v-if="!accessibilityStatementDisabled">
      <div class="GroupSummary_Others_Group">
        <div :class="['GroupSummary_Others_Group_Header', '_noClick']">
          <Icon class="GroupSummary_Others_Group_Header_Icon" type="solid" icon="file-lines" />
          <h3 class="GroupSummary_Others_Group_Header_Title">Accessibility statement quality</h3>
          <Pill :hintMode="true" :widerTooltip="true" :text="'?'" :tooltip="$gettext( 'For assurance purposes, please enter the details of your existing accessibility statement for this %{project}. If you don\'t have an existing statement for this %{project}, you can generate your statement using Hugr once you have conducted testing.', { project: $hugrConfig.projectString } )" :type="'positive'" :circle="true" size="micro" />
        </div>
        <div class="GroupSummary_Others_Group_SubHeadLeft">
          <Button size="micro" type="transparent" :icon="['regular', 'pen-to-square']" @click="$emit('editRenderDetails')" aria-label="Add or edit data">Add/Edit data</Button>
        </div>
        <div v-if="loading">
          <Loader />
        </div>
        <div v-else-if="!reportVersion.accessibilityStatementOverrides">
          <p>No data</p>
        </div>
        <dl class="GroupSummary_Main_Details _separator" v-else>
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( 'Statement location' ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <template v-if="!reportVersion.accessibilityStatementOverrides.statementURL || reportVersion.accessibilityStatementOverrides.statementURL == ''">
                <div @mouseenter="tooltips['statementURL']?.showTooltip()"
                      @mouseleave="tooltips['statementURL']?.hideTooltip()"
                      @focus="tooltips['statementURL']?.showTooltip()"
                      @blur="tooltips['statementURL']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('statementURL')?'0':'-1'">
                  No data
                </div>
              </template>
              <Link v-else-if="reportVersion.accessibilityStatementOverrides.statementURL.indexOf('http')>=0" :external="true" :href="reportVersion.accessibilityStatementOverrides.statementURL" target="_blank">{{ reportVersion.accessibilityStatementOverrides.statementURL }}</Link>
              <template v-else>
                <div @mouseenter="tooltips['statementURL']?.showTooltip()"
                      @mouseleave="tooltips['statementURL']?.hideTooltip()"
                      @focus="tooltips['statementURL']?.showTooltip()"
                      @blur="tooltips['statementURL']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('statementURL')?'0':'-1'">
                  {{ reportVersion.accessibilityStatementOverrides.statementURL }}
                </div>
              </template>
              <Tooltip v-if="getOverrideTooltip('statementURL')" :ref="(el) => tooltips['statementURL'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('statementURL').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('statementURL').user.name }`,`Previous value: ${getOverrideTooltip('statementURL').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('statementURL').extra.split(';-delimiter-;')[3]}`]"/>
            </dd>
            </div>
          </div>

          <div v-if="( reportVersion.accessibilityStatementOverrides.statementURL && reportVersion.accessibilityStatementOverrides.statementURL != '' ) ">
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( 'Last reviewed date stated' ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <template v-if="!reportVersion.accessibilityStatementOverrides.lastReviewDate || reportVersion.accessibilityStatementOverrides.lastReviewDate == ''">
                <div @mouseenter="tooltips['lastReviewDate']?.showTooltip()"
                      @mouseleave="tooltips['lastReviewDate']?.hideTooltip()"
                      @focus="tooltips['lastReviewDate']?.showTooltip()"
                      @blur="tooltips['lastReviewDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastReviewDate')?'0':'-1'">
                  No data
                </div>
              </template>
              <template v-else-if="moment(reportVersion.accessibilityStatementOverrides.lastReviewDate, true).isValid()" >
                <div @mouseenter="tooltips['lastReviewDate']?.showTooltip()"
                      @mouseleave="tooltips['lastReviewDate']?.hideTooltip()"
                      @focus="tooltips['lastReviewDate']?.showTooltip()"
                      @blur="tooltips['lastReviewDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastReviewDate')?'0':'-1'"
                      :class="[calculatedExpiryDateDifference <= 0?'Negative':'Positive']">
                  {{ moment(reportVersion.accessibilityStatementOverrides.lastReviewDate).format('Do MMMM YYYY') }}
                </div>
              </template>
              <template v-else>
                <div @mouseenter="tooltips['lastReviewDate']?.showTooltip()"
                      @mouseleave="tooltips['lastReviewDate']?.hideTooltip()"
                      @focus="tooltips['lastReviewDate']?.showTooltip()"
                      @blur="tooltips['lastReviewDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastReviewDate')?'0':'-1'">
                  {{ reportVersion.accessibilityStatementOverrides.lastReviewDate }}
                </div>
              </template>
              <Tooltip v-if="getOverrideTooltip('lastReviewDate')" :ref="(el) => tooltips['lastReviewDate'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('lastReviewDate').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('lastReviewDate').user.name }`,`Previous value: ${getOverrideTooltip('lastReviewDate').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('lastReviewDate').extra.split(';-delimiter-;')[3]}`]" />
            </dd>
            </div>
            <div :class="['GroupSummary_Main_Details_Item_Notice', calculatedExpiryDateDifference <= 0?'_negative':'']" v-if="calculatedExpiryDateDifference">
              <div v-if="calculatedExpiryDateDifference <= 0">
                <p>
                  <Icon type="solid" icon="triangle-exclamation" />
                  {{ moment(reportVersion.accessibilityStatementOverrides.lastReviewDate, true).add(12, 'months').from(moment(), true) }} out of date
                </p>
              </div>
              <div v-else>
                <p>
                  <Icon type="solid" icon="circle-check" />
                  {{ moment(reportVersion.accessibilityStatementOverrides.lastReviewDate, true).from(moment()) }}. {{ moment().to(moment(reportVersion.accessibilityStatementOverrides.lastReviewDate, true).add(12, 'months'), true) }} until invalid.
                </p>
              </div>
            </div>
          </div>
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( '%{project} compliance stated', { project: $hugrConfig.projectStringCap }  ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <template v-if="!reportVersion.accessibilityStatementOverrides.complianceStatus || reportVersion.accessibilityStatementOverrides.complianceStatus == ''">
                <div @mouseenter="tooltips['complianceStatus']?.showTooltip()"
                      @mouseleave="tooltips['complianceStatus']?.hideTooltip()"
                      @focus="tooltips['complianceStatus']?.showTooltip()"
                      @blur="tooltips['complianceStatus']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('complianceStatus')?'0':'-1'">
                  No data
                </div>
              </template>
              <template v-else>
                <div @mouseenter="tooltips['complianceStatus']?.showTooltip()"
                      @mouseleave="tooltips['complianceStatus']?.hideTooltip()"
                      @focus="tooltips['complianceStatus']?.showTooltip()"
                      @blur="tooltips['complianceStatus']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('complianceStatus')?'0':'-1'"
                      :class="[reportVersion.accessibilityStatementOverrides.complianceStatus.indexOf('Fully compliant') >= 0?'Positive':reportVersion.accessibilityStatementOverrides.complianceStatus.indexOf('Partially compliant') >= 0?'Advisory':reportVersion.accessibilityStatementOverrides.complianceStatus.indexOf('Not compliant') >= 0?'Negative':'']">
                  {{ reportVersion.accessibilityStatementOverrides.complianceStatus }}
                </div>
                <Tooltip v-if="getOverrideTooltip('complianceStatus')" :ref="(el) => tooltips['complianceStatus'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('complianceStatus').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('complianceStatus').user.name }`,`Previous value: ${getOverrideTooltip('complianceStatus').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('complianceStatus').extra.split(';-delimiter-;')[3]}`]" />
              </template>
            </dd>
            </div>
          </div>
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( 'Legal wording' ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <template v-if="!reportVersion.accessibilityStatementOverrides.legalStatus || reportVersion.accessibilityStatementOverrides.legalStatus == ''">
                <div @mouseenter="tooltips['legalStatus']?.showTooltip()"
                      @mouseleave="tooltips['legalStatus']?.hideTooltip()"
                      @focus="tooltips['legalStatus']?.showTooltip()"
                      @blur="tooltips['legalStatus']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('legalStatus')?'0':'-1'">
                  No data
                </div>
              </template>
              <template v-else>
                <div @mouseenter="tooltips['legalStatus']?.showTooltip()"
                      @mouseleave="tooltips['legalStatus']?.hideTooltip()"
                      @focus="tooltips['legalStatus']?.showTooltip()"
                      @blur="tooltips['legalStatus']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('legalStatus')?'0':'-1'"
                      :class="[reportVersion.accessibilityStatementOverrides.legalStatus.indexOf('Incorrect wording') >= 0?'Negative':reportVersion.accessibilityStatementOverrides.legalStatus.indexOf('Correct wording') >= 0?'Positive':'']">
                  {{ reportVersion.accessibilityStatementOverrides.legalStatus }}
                </div>
              </template>
              <Tooltip v-if="getOverrideTooltip('legalStatus')" :ref="(el) => tooltips['legalStatus'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('legalStatus').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('legalStatus').user.name }`,`Previous value: ${getOverrideTooltip('legalStatus').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('legalStatus').extra.split(';-delimiter-;')[3]}`]" />
            </dd>
            </div>
          </div>
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( 'Last assured' ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd>
              <template v-if="!reportVersion.accessibilityStatementOverrides.lastAssuredDate || reportVersion.accessibilityStatementOverrides.lastAssuredDate == ''">
                <div @mouseenter="tooltips['lastAssuredDate']?.showTooltip()"
                      @mouseleave="tooltips['lastAssuredDate']?.hideTooltip()"
                      @focus="tooltips['lastAssuredDate']?.showTooltip()"
                      @blur="tooltips['lastAssuredDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastAssuredDate')?'0':'-1'">
                  No data
                </div>
              </template>
              <template v-else-if="moment(reportVersion.accessibilityStatementOverrides.lastAssuredDate, true).isValid()" >
                <div @mouseenter="tooltips['lastAssuredDate']?.showTooltip()"
                      @mouseleave="tooltips['lastAssuredDate']?.hideTooltip()"
                      @focus="tooltips['lastAssuredDate']?.showTooltip()"
                      @blur="tooltips['lastAssuredDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastAssuredDate')?'0':'-1'">
                  {{ moment(reportVersion.accessibilityStatementOverrides.lastAssuredDate).format('Do MMMM YYYY') }}
                </div>
              </template>
              <template v-else>
                <div @mouseenter="tooltips['lastAssuredDate']?.showTooltip()"
                      @mouseleave="tooltips['lastAssuredDate']?.hideTooltip()"
                      @focus="tooltips['lastAssuredDate']?.showTooltip()"
                      @blur="tooltips['lastAssuredDate']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('lastAssuredDate')?'0':'-1'">
                  {{ reportVersion.accessibilityStatementOverrides.lastAssuredDate }}
                </div>
              </template>
              <Tooltip v-if="getOverrideTooltip('lastAssuredDate')" :ref="(el) => tooltips['lastAssuredDate'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('lastAssuredDate').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('lastAssuredDate').user.name }`,`Previous value: ${getOverrideTooltip('lastAssuredDate').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('lastAssuredDate').extra.split(';-delimiter-;')[3]}`]" />
            </dd>
          </div>
          </div>
          </div>
          <div class="GroupSummary_Main_Details_Item">
            <div class="GroupSummary_Main_Details_Item_Left">
              <dt>{{ $gettext( 'Notes' ) }}:</dt>
            </div>
            <div class="GroupSummary_Main_Details_Item_Right">
            <dd class="_longText">
              <template v-if="!reportVersion.accessibilityStatementOverrides.assuranceNote || reportVersion.accessibilityStatementOverrides.assuranceNote == ''">
                <div @mouseenter="tooltips['assuranceNote']?.showTooltip()"
                      @mouseleave="tooltips['assuranceNote']?.hideTooltip()"
                      @focus="tooltips['assuranceNote']?.showTooltip()"
                      @blur="tooltips['assuranceNote']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('assuranceNote')?'0':'-1'">
                  No data
                </div>
              </template>
              <template v-else>
                <div @mouseenter="tooltips['assuranceNote']?.showTooltip()"
                      @mouseleave="tooltips['assuranceNote']?.hideTooltip()"
                      @focus="tooltips['assuranceNote']?.showTooltip()"
                      @blur="tooltips['assuranceNote']?.hideTooltip()"
                      :tabindex="getOverrideTooltip('assuranceNote')?'0':'-1'">
                  {{ reportVersion.accessibilityStatementOverrides.assuranceNote }}
                </div>
              </template>
              <Tooltip v-if="getOverrideTooltip('assuranceNote')" :ref="(el) => tooltips['assuranceNote'] = el" :text="[`Changed on: ${moment(getOverrideTooltip('assuranceNote').timestamp).format('Do MMMM YYYY')}`,`Changed by: ${getOverrideTooltip('assuranceNote').user.name }`,`Previous value: ${getOverrideTooltip('assuranceNote').extra.split(';-delimiter-;')[3] == ''?'No data':getOverrideTooltip('assuranceNote').extra.split(';-delimiter-;')[3]}`]" :closeness="'far'" />
            </dd>
          </div>
          </div>
        </dl>
      </div>
    </div>

    <div class="GroupSummary_Others" v-if="Array.isArray( components ) || Array.isArray( userJourneys ) || Array.isArray( pages )">
      <div class="GroupSummary_Others_Group" v-if="Array.isArray( components ) && !loading">
        <div class="GroupSummary_Others_Group_Header" @click="toggleComponents()">
          <Icon class="GroupSummary_Others_Group_Header_Icon" type="solid" icon="puzzle-piece" />
          <h3 class="GroupSummary_Others_Group_Header_Title">Components</h3>
          <Pill :text="components.length" size="small" />
          <span v-if="failedComponents.length > 0" :style="'position: relative'">
            <span class="GroupSummary_Others_Group_Header_Failed"
                      @mouseenter="tooltips['failedComponents']?.showTooltip()"
                      @mouseleave="tooltips['failedComponents']?.hideTooltip()"
                      @focus="tooltips['failedComponents']?.showTooltip()"
                      @blur="tooltips['failedComponents']?.hideTooltip()"
                      tabindex="0">
                  {{ failedComponents.length }} non-compliant
            </span>
            <Tooltip :ref="(el) => tooltips['failedComponents'] = el" :text="'Please note, the presence of non-compliant components on a page will impact the overall compliance of the page'" :closeness="'close'" />
          </span>

          <button class="GroupSummary_Others_Group_Header_Button" @click.prevent.stop="toggleComponents()" :aria-label="componentsExpanded?'Collapse Components':'Expand Components'" :title="componentsExpanded?'Collapse Components':'Expand Components'">
            <Icon type="solid" :icon="componentsExpanded?'chevron-up':'chevron-down'" />
          </button>
        </div>
        <div v-if="componentsExpanded" class="GroupSummary_Others_Group_SubHead">
          <p>Components used on this page</p>
          <Button v-if="!reportVersion.published" size="micro" type="transparent" :icon="['solid', 'plus']" @click="addComponent()" aria-label="Add a component">Add</Button>
        </div>
        <ul v-if="componentsExpanded" class="GroupSummary_Others_Group_List">
          <li v-for="component of components" :key="component._id" class="GroupSummary_Others_Group_List_Item">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${reportVersion.report.identifier}/${reportVersion.version}/components/${component._id}`" role="button" @click.prevent="e => goToComponent( e, component._id )" :title="component.identifier">{{ component.identifier }}</router-link>
            <Button v-if="!reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removeComponent( component._id )" title="Remove page from component">Remove page from component</Button>
            
            <div class="GroupSummary_Others_Group_List_Item_Right">
              <CompliancePill :progress="component.progress" :isCompliant="component.isCompliant" :issues="component.issues.length" size="small" :truncate="true"/>
            </div>
          </li>
        </ul>
      </div>

      <div class="GroupSummary_Others_Group" v-if="Array.isArray( pages ) && !loading">
        <div class="GroupSummary_Others_Group_Header" @click="togglePages()">
          <Icon class="GroupSummary_Others_Group_Header_Icon" type="regular" icon="file" />
          <h3 class="GroupSummary_Others_Group_Header_Title">Featured on</h3>
          <Pill :text="pages.length" size="small" />

          <button class="GroupSummary_Others_Group_Header_Button" @click.prevent.stop="togglePages()" :aria-label="pagesExpanded?'Collapse Pages':'Expand Pages'" :title="pagesExpanded?'Collapse Pages':'Expand Pages'">
            <Icon type="solid" :icon="pagesExpanded?'chevron-up':'chevron-down'" />
          </button>
        </div>
        <div v-if="pagesExpanded" class="GroupSummary_Others_Group_SubHead">
          <p>Pages this component is used on</p>
        </div>
        <ul v-if="pagesExpanded" class="GroupSummary_Others_Group_List">
          <li v-for="page of pages" :key="page._id" class="GroupSummary_Others_Group_List_Item">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${reportVersion.report.identifier}/${reportVersion.version}/pages/${page._id}`" role="button" @click.prevent="e => goToPage( e, page._id )" :title="page.name">{{ page.name}}</router-link>
            <Button v-if="!reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removePage( page._id )" title="Remove component from page">Remove component from page</Button>
            
            <div class="GroupSummary_Others_Group_List_Item_Right">
              <CompliancePill :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issues.length" size="small" :truncate="true"/>
            </div>
          </li>
        </ul>
      </div>

      <div class="GroupSummary_Others_Group" v-if="Array.isArray( userJourneys ) && !loading">
        <div class="GroupSummary_Others_Group_Header" @click="toggleUserJourneys()">
          <Icon class="GroupSummary_Others_Group_Header_Icon" type="solid" icon="route" />
          <h3 class="GroupSummary_Others_Group_Header_Title">User Journeys</h3>
          <Pill :text="userJourneys.length" size="small" />

          <button class="GroupSummary_Others_Group_Header_Button" @click.prevent.stop="toggleUserJourneys()" :aria-label="userJourneysExpanded?'Collapse User Journeys':'Expand User Journeys'" :title="componentsExpanded?'Collapse User Journeys':'Expand User Journeys'">
            <Icon type="solid" :icon="userJourneysExpanded?'chevron-up':'chevron-down'" />
          </button>
        </div>
        <div v-if="userJourneysExpanded" class="GroupSummary_Others_Group_SubHead">
          <p>User journeys this page is on</p>
          <Button v-if="!reportVersion.published" size="micro" type="transparent" :icon="['solid', 'plus']" @click="addUserJourney()" aria-label="Add to a user journey">Add</Button>
        </div>
        <ul v-if="userJourneysExpanded" class="GroupSummary_Others_Group_List">
          <li v-for="journey of userJourneys" :key="journey._id" class="Others_Group_List_Item">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${reportVersion.report.identifier}/${reportVersion.version}/journeys/${journey._id}`" role="button" @click.prevent="e => goToUserJourney( e, journey._id )" :title="journey.title">{{ journey.title }}</router-link>
            <Button v-if="!reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removeFromJourney( journey._id )" title="Remove page from journey">Remove page from journey</Button>
            
            <div class="GroupSummary_Others_Group_List_Item_Right">
              <CompliancePill :progress="journey.progress" :isCompliant="journey.isCompliant" :issues="journey.issues.length" size="small" :truncate="true"/>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <UserJourneyModal v-if="reportVersion._id" :reportVersion="reportVersion._id" ref="userjourneymodal" @success="refetch"/>
  <ComponentModal v-if="Array.isArray( components )&&reportVersion._id" :report="reportVersion._id" ref="componentmodal" @added="refetch"/>
</template>

<script setup>
  import { onMounted, ref, toRefs, watch, computed, inject } from 'vue';
  import { useMutation, useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  import Link from '@/components/Helpers/Link';
  import moment from 'moment';
  import CompliancePill from '@/components/CompliancePill.vue';
  import Tooltip from './Tooltip.vue';
  import Pill from '@/components/UI/Pill.vue';
  import Pie from '@/components/UI/Pie.vue';
  import { onUnmounted } from 'vue';

  import UserJourneyModal from '@/modals/UserJourney/Create';
  import ComponentModal from '@/modals/Component/Create';
  import Screenshot from '@/components/Helpers/Screenshot';

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const { onResult: onSettings } = useQuery(
    gql`
      query Settings {
        settings: Settings {
          id
          showScreenshots
        }
      }
    `,
  );
  const showScreenshots = ref( false );
  onSettings( ( { data } ) => {
    if( !data ) return;

    showScreenshots.value = data.settings.showScreenshots;
  } );

  const props = defineProps( {
    loading: {
      type: Boolean,
      default: false,
    },
    csvExport: {
      type: Boolean,
      default: false,
    },
    wcagVersion: {
      type: String,
      default: '2.2',
    },
    wcagLevel: {
      type: String,
      default: 'AA',
    },
    type: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    typeEnding: {
      type: String,
      required: false,
      default: 'Summary',
    },
    details: {
      type: Object,
      required: true,
    },
    screenshot: {
      type: String,
      default: '',
    },
    contextItems: {
      type: Object,
      required: false,
    },
    progress: Number,
    isCompliant: Boolean,
    issues: Array,
    accessibilityStatementDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    accessibilityStatementLink: {
      type: String,
      required: false,
      default: '',
    },
    complianceEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    pieEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },

    reportVersion: Object,

    components: Object,
    pages: Object,
    userJourneys: Object,

    offsetUp: {
      type: Number,
      default: 0,
    },
  } );

  const {
    wcagVersion,
    wcagLevel,
    type,
    typeEnding,
    details,
    screenshot,
    contextItems,
    progress,
    isCompliant,
    complianceEnabled,
    accessibilityStatementDisabled,
    pieEnabled,
    issues,
    itemId,

    reportVersion,

    components,
    userJourneys,
  } = toRefs( props );

  const pieData = ref( [] );
  const pieKey = ref( null );

  const setPieData = () => {
    if( issues.value.length == 1 && issues.value[0].name == 'mockIssue' ) return;
    pieData.value = [
      {
        key: 'Critical',
        value: issues.value.filter( i => i.template.severity == 4 ).length,
        colour: '#B22C2A',
      },
      {
        key: 'High',
        value: issues.value.filter( i => i.template.severity == 3 ).length,
        colour: '#FAC0BF',
      },
      {
        key: 'Medium',
        value: issues.value.filter( i => i.template.severity == 2 ).length,
        colour: '#FBB55E',
      },
      {
        key: 'Low',
        value: issues.value.filter( i => i.template.severity == 1 ).length,
        colour: '#009620',
      },
      {
        key: 'Advisory',
        value: issues.value.filter( i => i.template.severity == 0 ).length,
        colour: '#879441',
      },
    ];
  };

  watch( issues, () => setPieData() );
  setPieData();

  const tooltips = ref( {} );

  const componentsExpanded = ref( false );
  const toggleComponents = () => {
    componentsExpanded.value = !componentsExpanded.value;
  };

  const pagesExpanded = ref( false );
  const togglePages = () => {
    pagesExpanded.value = !pagesExpanded.value;
  };

  const userJourneysExpanded = ref( false );
  const toggleUserJourneys = () => {
    userJourneysExpanded.value = !userJourneysExpanded.value;
  };

  const componentmodal = ref( null );

  const addComponent = () => {
    componentmodal.value.show();
    componentmodal.value.setAddToExisting( itemId.value );
    componentmodal.value.addPage( itemId.value );
  };

  const userjourneymodal = ref( null );

  const addUserJourney = () => {
    userjourneymodal.value.show();
    userjourneymodal.value.setAddToExisting( itemId.value, type.value );
    if( type.value == 'component' ) userjourneymodal.value.addComponent( itemId.value );
    if( type.value == 'page' ) userjourneymodal.value.addPage( itemId.value );
  };

  const contextMenuBtnRef = ref( null );
  const contextMenuRef = ref( null );
  const contextMenuOpen = ref( false );

  const failedComponents = computed( () => {
    const returnArray = [];
    if ( Array.isArray( components.value ) && components.value.length > 0 ) {
      components.value.forEach( component => {
        if ( !component.isCompliant ) {
          returnArray.push( component );
        }
      } );
    }

    return returnArray;
  } );

  const calculatedExpiryDateDifference = computed( () => {
    if ( moment( reportVersion.value.accessibilityStatementOverrides.lastReviewDate, true ).isValid() ) {
      return moment( reportVersion.value.accessibilityStatementOverrides.lastReviewDate, true ).add( 12, 'months' ).diff( moment() );
    }

    return false;
  } );

  const enabledContextItems = computed( () => {
    const returnArray = [];
    if ( Array.isArray( contextItems.value ) && contextItems.value.length > 0 ) {
      contextItems.value.forEach( item => {
        if ( item.enabled ) {
          returnArray.push( item );
        }
      } );
    }

    return returnArray;
  } );

  function getOverrideTooltip( variable ) {
    const overrideLogs = reportVersion.value.latestStatementOverrideNotifications;

    if ( overrideLogs.filter( item => item.extra.indexOf( variable ) == 0 ).length > 0 ) {
      return overrideLogs.filter( item => item.extra.indexOf( variable ) == 0 )[0];
    }

    return false;
  }

  const toggleContextMenuOff = ( ) => {
    const el = contextMenuRef.value;
    el.classList.remove( '_show' );
    contextMenuOpen.value = false;
  };
  const toggleContextMenu = ( ) => {
    const el = contextMenuRef.value;
    if( el.classList.contains( '_show' ) ) {
      toggleContextMenuOff( );
    } else {
      el.classList.add( '_show' );
      contextMenuOpen.value = true;
      const listener = e => {
        if( e.type == "click" || e.key == "Tab" ) {
          const parentEl = contextMenuRef.value;
          if ( parentEl != null ) {
            // Mark child by checking if it contains parts of the SVG string (this is because a click handler can have varying srcElements on custom Button, and will always be contained up to the parent button element)
            const isChild = e.target.outerHTML.indexOf( 'd="M64 360a56' ) > -1 || parentEl.contains( e.target );
            if( !isChild ) {
              toggleContextMenuOff( );
              document.removeEventListener( 'click', listener, false );
              document.removeEventListener( 'keyup', listener, false );
            }
          }
        }
      };
      document.addEventListener( 'click', listener );
      document.addEventListener( 'keyup', listener );
    }
  };

  const emit = defineEmits( [ 'refetch' ] );

  const refetch = () => {
    emit( 'refetch' );
  };

  const { mutate: removeFromUserJourney } = useMutation(
    gql`
    mutation removeFromUserJourney($id: ObjectID!, $item: ObjectID!, $type: String!) {
      journey: removeFromUserJourney(id: $id, item: $item, type: $type) {
        _id,
      }
    }
  ` );

  const removeFromJourney = journey => {
    confirm.simple().then( result => {
      if( result ) {
        removeFromUserJourney( {
          id: journey,
          item: itemId.value,
          type: type.value,
        } ).then( () => {
          alerts.success( `${type.value} removed from journey` );
          refetch();
        } ).catch( () => {
          alerts.generic(); //see notifications spreadsheet
        } );
      }
    } );
  };

  const { mutate: removePageFromComponent } = useMutation(
    gql`
    mutation removePageFromComponent($page: ObjectID!, $component: ObjectID!) {
      component: removePageFromComponent(page: $page, component: $component) {
        _id,
      }
    }
  ` );

  const removeComponent = component => {
    confirm.simple().then( result => {
      if( result ) {
        removePageFromComponent( {
          page: itemId.value,
          component,
        } ).then( () => {
          alerts.success( 'Component removed' );
          refetch();
        } ).catch( () => {
          alerts.generic(); //see notifications spreadsheet
        } );
      }
    } );
  };

  const removePage = page => {
    confirm.simple().then( result => {
      if( result ) {
        removePageFromComponent( {
          component: itemId.value,
          page,
        } ).then( () => {
          alerts.success( 'Component removed' );
          refetch();
        } ).catch( () => {
          alerts.generic(); //see notifications spreadsheet
        } );
      }
    } );
  };

  const summary = ref( null );
  const offset = ref( 0 );
  
  const scroll = () => {
    const fromTop = offset.value - window.scrollY;
    if( fromTop <= 100 && window.innerWidth >= 1400 ) {
      summary.value.classList.add( '_fixed' );
    } else {
      summary.value.classList.remove( '_fixed' );
      offset.value = summary.value.getBoundingClientRect().top;
    }
  };

  onMounted( () => {
    offset.value = summary.value.getBoundingClientRect().top;
    if( offset.value < 100 ) offset.value = 240;
    window.addEventListener( 'scroll', scroll );
  } );
  onUnmounted( () => {
    window.removeEventListener( 'scroll', scroll );
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_sizes.scss';
  @import '@/assets/styles/variables/_header.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .Positive {
    color: $hugr-colours-pills-positive;
  }

  .Negative {
    color: $hugr-colours-pills-negative;
  }

  .Advisory {
    color: $hugr-colours-pills-warning;
  }

  .GroupSummary {
    &._offset-up-8 { margin-top: -8px; }
    &._offset-up-16 { margin-top: -16px; }
    &._offset-up-32 { margin-top: -32px; }
    &._offset-up-64 { margin-top: -64px; }
    &._fixed {
      position: fixed;
      width: $groupSummary-width;
      top: calc( $hugr-header-regular-height + 16px );
      margin-top: 0;

      height: calc( 100vh - $hugr-header-regular-height - 32px );
      @include vertical-scroll;
    }

    &_Main {
      background: lighten( $hugr-colours-grey, 15% );
      padding: 8px;
      ._separator {
        border-top: 1px solid $hugr-colours-grey;
        padding: 8px 0;
        margin-bottom: 4px;
      }

      &_Screenshot {
        margin-bottom: 4px;
      }

      &_Chart {
        &_Title {
          margin: 8px 0;
          margin-left: 8px;
          font-size: 1.2em;
          font-weight: bold;
        }
      }
      
      &_Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_Title {
          margin: 8px 0;
          margin-left: 8px;
          font-size: 1.2em;
          font-weight: bold;
        }
      }

      &_Details {
        &_Item {
          min-width: 90%;
          max-width: 100%;
          margin-left: 8px;
          margin-right: 8px;
          font-size: 0.9em;
          color: $hugr-colours-primary;
          margin-bottom: 8px;
          clear: both;
          display: flex;
          flex-wrap: wrap;

          &_Left {
            width: 30%;
          }

          &_Right {
            width: 70%;
            max-height: 52px;
            overflow: hidden;
            position: relative;
          }

          &_Notice {
            display: flex;
            justify-content: center;
            margin: 8px 0px;
            min-height: 30px;
            align-items: center;
            border-radius: 4px;
            width: 100%;

            color: white;
            background: $hugr-colours-green;
            
            &._negative {
              background: $hugr-colours-red;
            }
          }

          dt {
            display: inline;
            vertical-align: top;
            font-weight: bold;
          }
          dd {
            display: inline;
            vertical-align: top;
            text-align: right;
            float: right;
            margin-left: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            &._longText {
              white-space: wrap;
              max-height: 60px;
              overflow: auto;
              margin-bottom: 8px;
            }

            a {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &_Compliance {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_Title {
          margin-left: 8px;
          display: inline-block;
          vertical-align: top;
          width: 60%;
          h4 {
            color: $hugr-colours-primary;
            margin: 0;
            font-weight: bold;
          }
          p {
            color: $hugr-colours-primary;
            margin: 0;
            font-size: 0.8em;
          }
        }
        &_Contents {
          display: inline-block;
          vertical-align: top;
          right: 20px;
        }
      }

      &_Statement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_Title {
          display: flex;
          margin-left: 8px;
          align-items: center;
          width: 60%;
          h4 {
            color: $hugr-colours-primary;
            margin: 0;
            font-weight: bold;
          }
          p {
            color: $hugr-colours-primary;
            margin: 0;
            font-size: 0.8em;
          }
        }
        &_Contents {
          display: inline-block;
          vertical-align: top;
          right: 20px;
        }
      }
    }

    &_Others {
      background: lighten( $hugr-colours-grey, 15% );
      padding: 8px;
      margin-top: 16px;
      &_Group {
        &_Header {
          padding: 8px;
          position: relative;
          cursor: pointer;

          &_Failed {
            color: $hugr-colours-red;
            font-size: 12px;
            margin-left: 8px;
          }

          &_noClick {
            cursor: auto;
          }

          &_Icon {
            display: inline;
          }
          &_Title {
            display: inline;
            margin: 0 8px;
            font-size: 0.9em;
            font-weight: bold;
          }

          &_Button {
            background: transparent;
            border: none;
            position: absolute;
            right: 16px;
            top: 12px;
            cursor: pointer;
          }
        }

        &_SubHead {
          p {
            font-size: 0.8em;
            color: lighten( $hugr-colours-primary, 10% );
            display: inline;
          }
          button {
            display: inline-block;
            float: right;
            position: relative;
            top: -4px;
          }
        }

        &_SubHeadLeft {
          margin-bottom: 32px;
          p {
            font-size: 0.8em;
            color: lighten( $hugr-colours-primary, 10% );
            display: inline;
          }
          button {
            display: inline-block;
            float: left;
            position: relative;
            top: -4px;
          }
        }

        &_List {
          list-style: none;
          padding: 0;
          width: 100%;
          &_Item {
            background: $hugr-colours-input-surface;
            padding: 8px;
            position: relative;
            margin-bottom: 12px;
            border-radius: 8px;
            > * {
              vertical-align: middle;
            }
            a {
              display: inline-block;
              max-width: calc( 100% - 64px );
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-decoration: none;
            }
            &_Right {
              display: inline-block;
              float: right;
              padding: 6px;
              padding-bottom: none;
            }
          }
        }
      }
    }
  }

  ._darkMode .Positive {
    color: lighten( $hugr-colours-pills-positive, 10%);
  }

  ._darkMode .Negative {
    color: lighten( $hugr-colours-pills-negative, 10%);
  }

  ._darkMode .Advisory {
    color: lighten( $hugr-colours-pills-warning, 10%);
  }

  ._darkMode .GroupSummary{
    
    &_Main {
      background: darken( $hugr-colours-primary, 10% );
      color: white;

      p {
        color: white;
      }

      &_Details {
        &_Item {
          &_Left {
            color: white;
          }
          &_Right {
            color: white;
          }
        }
      }

      &_Header {
        &_ContextButton {
          color: white !important;
          &:hover, &:focus, &:active {
            color: $hugr-colours-secondary !important;
          }
        }

        &_ContextMenu {
          border: 1px solid white;
          background: darken( $hugr-colours-primary, 10%);
          &_Item {
            background: black;
          }
        }
      }
    }

    &_Others {
      background: darken( $hugr-colours-primary, 10% );
      color: white;
      &_Group {
        &_Header {
          &_Failed {
            color: $hugr-colours-red-darkmode;
          }
          &_Button {
            color: white;
          }
        }
        &_SubHead {
          p { color: white; }
        }
        &_List {
          &_Item {
            background: darken( $hugr-colours-primary, 5% );
          }
        }
      }
    }
  }
</style>
