<template>
  <div class="Ticket">
    <!-- <Button size="small" type="secondary" @click="$router.push(`/team/${instance.team.identifier}`)">Back to team's page</Button>
    <Button size="small" type="secondary" @click="$refs.ticketlistmodal.show()">Show Team Tickets</Button> -->
    <Body v-if="instance" :title="`Ticket: ${instance.title}`" :subtitle="`#${instance.reference}`" :aside="true" class="Ticket_Body">

      <div class="Ticket_Summary">
        <div class="edit-head">
          <h3 v-translate>Ticket Summary</h3>
        </div>
        <vue-markdown :key="instance._id +'-summary'" v-highlight :html="false">{{ instance.extraInfo }}</vue-markdown>
      </div>

      <div class="Ticket_Screenshot" v-if="instance.screenshot">
        <div class="edit-head">
          <h3 v-translate>Screenshot</h3>
        </div>
        <div class="Ticket_Body_Screenshot_Img">
          <Screenshot v-if="instance.screenshot" :sID="instance.screenshot._id" alt="Screenshot of ticket" :lightbox="true"/>
        </div>
      </div>

      <Aside>
        <dl>
          <div>
            <dt id="statuslabel" v-translate>Status</dt>
            <dd>
              <span v-if="user.aclevel==2"> <!-- customer -->
                <span>{{$gettext(matchStatus(instance.status))}}</span>
              </span>
              <span v-else>
                <FormSelect idRoot="instance_" label="Status" :labelVisible="false" v-model="instance.status" @change="statusChange(instance.status)" v-bind:options="{
                  'open': $gettext('Open'),
                  'closed': $gettext('Closed'),
                  'replied': $gettext('Internal Reply')
                  }"/>
              </span>
            </dd>
          </div>
          <div>
            <dt v-translate>Ticket Type</dt>
            <dd>
              <span v-if="instance.ticketType">{{ matchType(instance.ticketType) }}</span>
              <span v-else v-translate>No type? This is a bug. </span>
            </dd>
          </div>
          <div>
            <dt v-translate>Team</dt>
            <dd>
              <span v-if="instance.team">{{ instance.team.name }}</span>
              <span v-else v-translate>No team? This is a bug. </span>
            </dd>
          </div>
          <div>
            <dt v-translate>Created By</dt>
            <dd>
              <span v-if="instance.createdBy">{{instance.createdBy.name}} ({{instance.createdBy.email}})</span>
              <span v-else v-translate>No creator? This is a bug. </span>
            </dd>
          </div>
          <div>
            <dt v-translate>Assigned To</dt>
            <dd>
              <span v-if="instance.assignedTo">{{instance.assignedTo.name}} ({{instance.assignedTo.email}})</span>
              <span v-else v-translate>No assignee? This is a bug. </span>
            </dd>
          </div>
          <div v-if="instance.report">
            <dt>{{ $gettext( '%{project}', { project: $hugrConfig.projectStringCap } ) }}</dt>
            <dd>
              {{instance.report.title}}
            </dd>
          </div>

        </dl>
      </Aside>

      <div class="edit-head">
        <h3 v-translate>Discussion thread</h3>
      </div>
      <Comments v-if="instance" v-bind:reference="instance._id" @posted="statusChange('comment')"/>

    </Body>
    <IssueDetailsModal ref="issuedetailsmodal"/>
    <!-- <TicketListModal ref="ticketlistmodal" v-if="instance.team" :team="instance.team._id"/> -->
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import UIBody from '@/components/UI/Body';
import UIAside from '@/components/UI/Aside';
// import TicketListModal from '@/modals/TicketList';

import FormSelect from '@/components/Form/Select';

import Comments from '@/components/Comments/Index';

import IssueDetailsModal from '@/modals/IssueTemplate/Details';

export default {
  name: 'TicketInstanceView',
  data() {
    return {
      identifier: this.$route.params.identifier,

      report: false,
      instance: false,

      group: false,
      groupInstances: false,

      solutionsChanged: false,
    };
  },
  watch: {
    $route() {
      this.identifier = this.$route.params.identifier;
      this.$apollo.queries.instance.refetch();
    },
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Ticket',
      },
    } );
  },
  apollo: {
    instance() {
      return {
      query() {
 return gql`
        query Ticket($ident: String!) {
          instance: Ticket(identifier: $ident) {
            _id,
            title,
            ticketType,
            extraInfo,
            reference,
            issue{
              identifier
            },
            report {
              _id,
              title
            },
            createdBy {
              name,
              email
            },
            assignedTo {
              name,
              email
            },
            status,
            screenshot {
              _id
            },
            team {
              _id,
              identifier,
              name
            }
          }
        }
      `;
},
      variables() {
        return {
          ident: this.identifier,
        };
      },
      fetchPolicy: 'no-cache',
    };
    },
  },
  methods: {
    matchType( type ) {
      switch( type ) {
        case( 'general' ): return 'General enquiry';
        case( 'testSection' ): return 'Help with testing a section';
        case( 'testPage' ): return 'Help with testing a page';
        case( 'helpIssue' ): return 'Help with an issue';
        case( 'fixIssue' ): return 'Help with fixing an issue';
      }
    },
    matchStatus( type ) {
      switch( type ) {
        case( 'open' ): return 'Open';
        case( 'closed' ): return 'Closed';
        case( 'replied' ): return 'DIG Reply';
      }
    },

    statusChange( status ) {
      if ( this.user.aclevel >= 2 ) {
        status = "open";
      } else if ( status == "comment" ) {
        status = "replied";
      }
      this.$apollo.mutate( {
        mutation: gql`
          mutation changeTicketStatus($id: ObjectID!, $status: String) {
            result: changeTicketStatus(id: $id, status: $status)
          }
        `,
        variables: {
          id: this.instance._id,
          status,
        },
      } ).then( res => {
        if ( res.data.result == true ) {
          this.$alerts.success( 'Status changed', `Issue instance status has been changed to ${status}` );
          this.instance.status = status;
        } else {
          this.$alerts.warn( 'Status not changed', `This ticket might be closed.` );
        }
      } ).catch( () => {
        this.$alerts.error( `Issue status not changed`, `There's a bug and you've found it` );
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    Body: UIBody,
    Aside: UIAside,
    FormSelect,
    Comments,

    IssueDetailsModal,
    // TicketListModal

  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.Ticket {
  &_Body {
    position: relative;

    &_Screenshot {
      margin-bottom: 36px;
      
      &_Img {
        width: 100%;
        max-height: 400px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
  div.edit-head {
    border-bottom: 1px solid #dce4ee;
    margin-bottom: 15px;
    margin-top: 30px;
    padding-bottom: 1px;
    h3 {
      margin: 0;
    }
    button {
      float: right;
      position: relative;
      top: -26px;
    }
  }

  figure {
    border: 1px solid #b2b3b2;
    background: #f6f8fa;
    padding: 10px;
    margin: 0;
    img {
      max-width: 100%;
    }
  }
</style>
