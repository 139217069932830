<template>
  <div class="Team" v-if="people.length>1" tabindex="0" @focus="doFocus()" @mouseenter="hoverPeople()" @mouseleave="blurPeople()">
    <h3 class="Team_Title">Team</h3>
    <Avatar v-for="(person, index) in people"
      :ref="setAvatars"
      :class="['Team_Avatar', `_person-${index}`, index<4&&!showAllPeople?`_startCollapsed`:'', index>4&&!showAllPeople?`_startHidden`:'']"
      :key="person[1]"
      :uid="person[1]"
      :details="true"
      size="small"
      type="round"
      :report="report?report._id:false"
      :multiMode="true"
      @keydown.right="traverseRight"
      @keydown.left="traverseLeft"
      @focus="hoverPeople()"
      @blur="blurPeople(e)"/>
    <Pill :class="['Team_Pill', showAllPeople?`_hide`:``]" v-if="people.length>5" :text="`${people.length-5} more`" />
  </div>
</template>

<script setup>
  import { toRefs, ref } from 'vue';

  import Avatar from '@/components/Helpers/Avatar.vue';
  import Pill from '@/components/UI/Pill.vue';

  const props = defineProps( {
    report: {
      type: Object,
      reqired: false,
    },
    people: {
      type: Object,
      reqired: true,
    },
  } );

  const { report, people } = toRefs( props );

  const avatars = ref( [] );
  const setAvatars = el => {
    if ( el ) avatars.value.push( el );
  };

  const showAllPeople = ref( false );
  let peopleTimeout;

  const hoverPeople = () => {
    clearTimeout( peopleTimeout );
    showAllPeople.value = true;
  };

  const blurPeople = () => {
    if( document.activeElement.classList.contains( 'Avatar_Actual' ) ) return;

    peopleTimeout = setTimeout( () => {
      showAllPeople.value = false;
    }, 500 );
  };

  const currentAvatar = ref( 0 );

  const doFocus = () => {
    avatars.value[ currentAvatar.value ].focus();
  };

  const traverseRight = () => {
    if( currentAvatar.value == ( avatars.value.length - 1 ) ) return;
    currentAvatar.value++;
    doFocus();
  };

  const traverseLeft = () => {
    if( currentAvatar.value == 0 ) return;
    currentAvatar.value--;
    doFocus();
  };
</script>

<style lang="scss" scoped>
  .Team {
    position: absolute;
    right: 64px;
    top: 64px;
    &_Title {
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      margin-right: 8px;
    }
    &_Avatar {
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
      width: 32px;

      transition: width .2s;

      &._startCollapsed {
        width: 20px;
      }

      &._startHidden {
        position: absolute !important;
        width: 1px;
        overflow: hidden;
      }
    }
    &_Pill {
      display: inline-block;
      vertical-align: middle;
      width: 68px;
      white-space: nowrap;
      overflow: hidden;

      transition: all .2s;

      &._hide {
        position: absolute !important;
        height: 1px; width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
      }
    }
  }
</style>
