<template>
  <Modal
    ref="modal"
    :title="$gettext('New User')"

    :showActions="true"

    :loading="submitting"
    :loadingText="inviting?'Inviting User...':'Adding User...'"

    :submitText="inviting?$gettext('Invite user'):$gettext('Add user')"
    @submit="inviting?inviteUser():submitUser()"

    @close="reset()">
    <template v-if="!inviting" >
      <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>
      <FormInput ref="email" idRoot="team_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
      <FormSelect idRoot="team_" :label="$gettext('User Type')" v-model="aclevel" :options="aclevels"/>
      <FormSelect v-if="hasPermission('SetUserPermissionGroup', 'Any') || ( hasPermission('SetUserPermissionGroup', 'WithinGroupCategories') && Object.keys(niceList).length > 0 )" :key="'permission-key'" ref="perms" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList"/>

      <FormInput ref="password" :type="passwordType" idRoot="team_" :label="$gettext('Password')" v-model="password" :validation="['not-empty']"/>
      <Button size="micro" @click="togglePasswordVisibility">{{$gettext(passwordTypeFlavorText)}}</Button>
    </template>
    <template v-else>
      <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>
      <FormInput ref="email" idRoot="team_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
      <FormSelect idRoot="team_" :label="$gettext('User Type')" v-model="aclevel" :options="aclevels"/>
      <FormSelect v-if="hasPermission('SetUserPermissionGroup', 'Any') || ( hasPermission('SetUserPermissionGroup', 'WithinGroupCategories') && Object.keys(niceList).length > 0 )" :key="'permission-key'" ref="perms" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList"/>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions, mapGetters } from 'vuex';
import md5 from 'md5';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'UserModal',
  data() {
    return {
      name: '',
      email: '',
      aclevel: 1,
      password: '',
      inviting: false,
      permissionGroups: false,
      selectedGroup: false,
      passwordType: 'password',
      passwordTypeFlavorText: 'Show Password',

      submitting: false,
    };
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
            categories {
              category {
                _id
              },
              hierarchy
            }
          }
        }
      `,
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
      this.passwordTypeFlavorText = this.passwordType == 'password' ? 'Show Password' : 'Hide Password';
    },
    submitUser() {
      this.$hugrvalidate( [ 'name', 'email', 'password' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation addUserAdmin($user: UserInput!) {
                user: addUserAdmin(user: $user) {
                  _id
                }
              }
            `,
            variables: {
              user: {
                name: this.name,
                email: this.email,
                aclevel: parseInt( this.aclevel ),
                password: md5( `${this.password}:alienFORGERY` ),
                permissionGroup: this.selectedGroup ? this.selectedGroup : null,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.$alerts.success( 'User added!', `User ${this.name} has been added successfully` );
            this.$emit( 'success', res.data.user._id );
            this.reset();
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.generic( error );
          } );
        }
      } );
    },
    inviteUser() {
      this.$hugrvalidate( [ 'name', 'email' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation inviteUser($email: String!, $name: String!, $aclevel: Int!, $permissionGroup: ObjectID) {
                invited: inviteUser(email: $email, name: $name, aclevel: $aclevel, permissionGroup: $permissionGroup)
              }
            `,
            variables: {
                name: this.name,
                email: this.email,
                aclevel: parseInt( this.aclevel ),
                permissionGroup: this.selectedGroup ? this.selectedGroup : null,
            },
          } ).then( res => {
            this.submitting = false;
            if ( res.data.invited ) {
              this.$alerts.success( 'User invited!', `User ${this.name} has been invited successfully.` );
              this.$emit( 'success' );
              this.reset();
            } else {
              this.$alerts.error( 'User not invited!', `User ${this.name} already exists and has signed up.` );
              this.$emit( 'success' );
              this.reset();
            }
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.generic( error );
          } );
        }
      } );
    },

    show( inviting = false ) {
      this.inviting = inviting;
      this.$refs.modal.show();
    },
    reset() {
      this.name = '';
      this.email = '';
      this.aclevel = 1;
      this.password = '';
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    aclevels() {
      const aclevels = {};
      if( this.user.aclevel == 0 ) aclevels[0] = 'Admin';
      if( this.user.aclevel <= 1 ) aclevels[1] = 'Tester';
      if( this.user.aclevel <= 2 ) aclevels[2] = 'Customer';
      
      return aclevels;
    },
    niceList() {
      const list = {};

      this.permissionGroups?.forEach( group => {
        let hierarchyMatch = false;

        group?.categories?.forEach( pairTwo => {
          this.user.permissionGroup?.categories.forEach( pair => {
            if ( ( pair.category._id == pairTwo.category._id && pair.hierarchy > pairTwo.hierarchy ) ) {
              hierarchyMatch = true;
            }
        } );
      } );

      if ( this.hasPermission( 'SetUserPermissionGroup', 'Any' ) || ( this.hasPermission( 'SetUserPermissionGroup', 'WithinGroupCategories' ) && hierarchyMatch ) ) {
        list[group._id] = group.name;
      }

      } );
      
      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
  },
  components: {
    FormInput,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
