<template>
  <div class="Settings">
    <Notice size="small" type="alert">{{ $gettext('This changes settings for all %{project} versions', { project: $hugrConfig.projectString }) }}</Notice>
    <Notice v-if="report.archived" type="alert">{{ $gettext('This %{project} is archived', { project: $hugrConfig.projectString } )}}</Notice>
    <ReportInfo :trail="[
      {name: $gettext('Home'), path: '/dashboard'},
      {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`},
      {name: 'THIS_REPORT'}
    ]" />

    <Body v-if="report" :withMenu="true" class="Settings_Body">
      <form v-show="!hasPermission('Restrictions','CanNotEditReportSettings')" @submit.prevent="submitReport" class="Settings_Body_Form">
        <FormInput idRoot="report_" :label="$gettext('Title')" :disabled="hasPermission('Restrictions','CanNotEditReportTitle')" v-model="report.title"/>

        <!-- <AriaSearchSelect v-if="parseInt(user.aclevel)<2" label="Team" :gqlOptions="['TEAMS']" @selected="setTeam" :ref="'team'" :default="report.team?report.team._id:''" :dropdown="true"/> -->
        <AriaSearchSelect v-if="parseInt(user.aclevel)<2" :label="$gettext('Customer')" :gqlOptions="['CUSTOMERS']"
          @selected="setCustomer" :ref="'customer'" :default="report.customer?report.customer._id:''"
          :dropdown="true" />
        <AriaSearchSelect v-if="report.owner._id==user.id||user.aclevel==0" :label="$gettext('Owner')" :gqlOptions="['USERS']" @selected="setOwner" :ref="'owner'"
          :default="report.owner._id" />
        <span v-else class="__small">{{ $gettext( 'This %{project} is owned by %{name} (%{email})', { project: $hugrConfig.projectString, name: report.owner.name, email: report.owner.email } ) }}</span>

        <TabList v-if="portfoliosOn" class="TeamPortfolioTabList" :lastIndex="lastKnownTab" @tabChanged="lastKnownTab = $event;" :tabs="portfolioTeamTabs"/>

        <div :id="portfoliosOn?'tabpane':''">
          <div v-show="tabbedToPortfolio">
            <template v-if="portfolios&&Object.keys(portfolios).length>3">
              <AriaListBox v-if="!hasPermission('Restrictions','CanNotEditReportTeamOrPortfolio')" idRoot="report_" :label="$gettext('Portfolio')" v-model="currPortfolio"
                :options="{
                  ...portfolios
                }"
              />
              <div v-else>
                {{ $gettext('Portfolio: %{assigned}', { assigned: currPortfolio?portfolios[currPortfolio]?portfolios[currPortfolio].label:'Assigned to portfolio without access':'None' }) }}
              </div>
            </template>
            <template v-else>
              <FormFieldSet :legend="$gettext('Portfolio')">
                <div v-for="(portfolioname, portfoliovalue) of portfolios" :key="`portfolio-${portfoliovalue}-container`">
                  <input type="radio" v-model="currPortfolio" :value="portfoliovalue" :id="`portfolio-${portfoliovalue}`"
                    :key="`portfolio-${portfoliovalue}`" :disabled="hasPermission('Restrictions','CanNotEditReportTeamOrPortfolio')" />
                  <label :for="`portfolio-${portfoliovalue}`" :key="`portfolio-${portfoliovalue}-label`">{{portfolioname}}</label>
                </div>
                {{ $gettext('Portfolio: %{assigned}', { assigned: currPortfolio?portfolios[currPortfolio]?portfolios[currPortfolio].label:'Assigned to portfolio without access':'None' }) }}
              </FormFieldSet>
            </template>
          </div>
          <div>
            <template v-if="teams&&Object.keys(teams).length>3">
              <AriaSearchSelect :style="'margin-top: 4px;'" v-if="!hasPermission('Restrictions','CanNotEditReportTeamOrPortfolio')" :label="$gettext('Team')" :gqlOptions="['TEAMS']" @selected="setTeam" :ref="'team'" :default="report.team?report.team._id:''" :dropdown="true"/>
              <div v-else>
                {{ $gettext('Assigned to %{assignee}', { assignee: currTeam==0?'me':typeof teams[currTeam] === 'object' && teams[currTeam] !== null?teams[currTeam].label : teams[currTeam] }) }}
              </div>
            </template>
            <template v-else>
              <FormFieldSet :legend="$gettext('Who will be working on this?')">
                <div>
                  <input type="radio" v-model="currTeam" value="0" :disabled="hasPermission('Restrictions','CanNotEditReportTeamOrPortfolio')" id="justme" />
                  <label for="justme">{{$gettext('Just Me')}}</label>
                </div>
                <div v-for="(teamname, teamvalue) of teams" :key="`team-${teamvalue}-container`">
                  <input type="radio" v-model="currTeam" :value="teamvalue" :id="`team-${teamvalue}`"
                    :key="`team-${teamvalue}`" :disabled="hasPermission('Restrictions','CanNotEditReportTeamOrPortfolio')" />
                  <label :for="`team-${teamvalue}`" :key="`team-${teamvalue}-label`">{{teamname}} ({{$gettext('Team')}})</label>
                </div>
                {{ $gettext('Assigned to %{assignee}', { assignee: currTeam==0?'me':typeof teams[currTeam] === 'object' && teams[currTeam] !== null?teams[currTeam].label : teams[currTeam] }) }}
              </FormFieldSet>
            </template>
          </div>

          <div v-show="tabbedToPortfolio">
            <br />
            <template v-if="report.owner._id==user.id||user.aclevel==0">
              <div>
                <input id="report-sensitive" type="checkbox" v-model="report.sensitive" aria-decsribedby="report-sensitive-hint">
                <label for="report-sensitive">Sensitive</label>
              </div>
              <span class="__small" id="report-sensitive-hint">
                {{ $gettext( 'Ticking this will mean that only the owner and team assigned will be able to access this %{projectString}, not the portfolio principals.', { projectString: $hugrConfig.projectString } ) }}
              </span>
            </template>
            <span class="__small" v-else>
              {{ $gettext( 'This %{projectString} %{sensitiveStatus} marked as sensitive', { projectString: $hugrConfig.projectString, sensitiveStatus: report.sensitive?'is':'is not' } ) }}
            </span>
          </div>
        </div>
        <!-- </template> -->

        <template v-if="user.teams.length > 0">
          <h4 v-translate>Collaborators</h4>
          <ul v-if="report.collaborators.length">
            <li v-for="(collaborator,index) in report.collaborators" v-bind:key="'collaborator-' + index">
              {{collaborator.name}} ({{collaborator.email}})
              &nbsp;
              <Button v-if="!hasPermission('Restrictions','CanNotEditReportCollaborators')" type="serious" size="micro" @click.prevent="rmCollaborator(collaborator)">{{$gettext('Remove')}}</Button>
            </li>
          </ul>

          <p v-else v-translate>No collaborators assigned</p>
          <AriaSearchSelect v-if="!hasPermission('Restrictions','CanNotEditReportCollaborators')" :label="$gettext('Add a collaborator')" idRoot="collaborator" :dropdown="true" :gqlOptions="['USERS']"
            :ref="'collaborator'" @selected="addCollaborator" />
        </template>

        <FormSelect idRoot="report_" :label="$gettext('Report Type')" v-model="report.template" :options="reportTemplateChoices"/>

        <template v-if="hasPermission('Reports', 'Anonymise') || user.aclevel == 0">
          <br />
          <label><input type="checkbox" v-model="report.anonymizeEmails" /> Anonymize Reporter Emails</label><br />
          <label><input type="checkbox" v-model="report.anonymizeNames" /> Anonymize Reporter Names</label>
        </template>

        <template v-if="internalTeamAccessOn && ( hasPermission('Reports', 'canSetInternalTeamAccess') || user.aclevel == 0 )">
          <br />
          <label>
            <input type="checkbox" v-model="report.internalTeamAccess" />
            {{ $gettext( 'Allow internal team to access %{project}', { project: $hugrConfig.projectString } ) }}
          </label><br />
        </template>

        <ButtonSet class="Settings_Body_Form_Actions">
          <Button type="primary" htype="submit">{{ $gettext('Save') }}</Button>
        </ButtonSet>

        <br />
        <br />

        <FormFieldSet :legend="$gettext( '%{project} Details', { project: $hugrConfig.projectStringCap } )" v-if="hasPermission('Reports', 'UpdateDetailsSection') || user.aclevel == 0">
          <FormInput v-if="productSettings" idRoot="_details" :label="$gettext('Aliases')" hintText='Comma separated' v-model="report.productAliases" />
          <TextArea idRoot="_details" :label="$gettext('Description')" v-model="report.productDescription" />
          <FormInput idRoot="_details" :label="$gettext('URL')" v-model="report.productUrl" />
          <Button type="primary" @click="saveProductDetails">{{ $gettext('Save') }}</Button>
        </FormFieldSet>

        <template v-if="productSettings">

          <FormFieldSet :legend="$gettext( '%{project} Risk', { project: $hugrConfig.projectStringCap } )" v-if="hasPermission('AD&I', 'UpdateRisk') || user.aclevel == 0">
            <FormSelect idRoot="_risk" :label="$gettext('Risk')" v-model="report.productRisk" :options="[$gettext('Low'), $gettext('Medium'), $gettext('High')]" />
            <TextArea idRoot="_risk" :label="$gettext('Description')" v-model="report.productRiskDescription" />
            <Button type="primary" @click="saveProductRisk">{{ $gettext('Save') }}</Button>
          </FormFieldSet>

          <FormFieldSet legend="Disproportionate Burden"
            v-if="hasPermission('AD&I','UpdateBurden') || user.aclevel == 0">
            <FormSelect idRoot="_burden" :label="$gettext('Disproportionate Burden')" v-model="report.disproportionateBurden"
              :options="[$gettext('None'), $gettext('Being considered'), $gettext('Made'), $gettext('Expired')]" />
            <TextArea idRoot="_burden" :label="$gettext('Description')" v-model="report.disproportionateBurdenDescription" />

            <Button type="primary" @click="saveDisproportionateBurden">{{ $gettext('Save') }}</Button>
          </FormFieldSet>

          <!-- <FormFieldSet :legend="$gettext( '%{project} Accessibility Statement (Compliance)', { project: $hugrConfig.projectStringCap } )"
            v-if="hasPermission('AccessibilityStatement','All') || user.aclevel == 0">
            <FormSelect idRoot="_a11y" :label="$gettext( '%{project} Accessibility Compliance Statement', { project: $hugrConfig.projectStringCap } )" v-model="report.accessibilityStatement"
              :options="[$gettext('Unknown'), $gettext('Partially - mostly unmet'), $gettext('Partially - mostly met'), $gettext('Met'), $gettext('Exceeded')]" />
            <FormInput idRoot="_details" :label="$gettext( '%{project} Accessibility Compliance Statement URL', { project: $hugrConfig.projectStringCap } )" v-model="report.productStatementUrl" />
            <TextArea idRoot="_a11y" :label="$gettext('Notes')" v-model="report.accessibilityStatementNotes" />
            <Button type="primary" @click="saveAccessibilityStatement(); saveProductDetails();">{{ $gettext('Save') }}</Button>
          </FormFieldSet> -->

          <!-- <FormFieldSet legend="User Journeys" v-if="hasPermission('UserJourneys', 'All') || user.aclevel == 0">
            <Button @click="$refs.userjourneymodal.show()">{{ $gettext('Add') }}</Button>

            <DataTable v-if="report.userJourneys.length" :headers="['Title', 'Description', 'Importance', 'Actions']">
              <tr v-for="userJourney in report.userJourneys" :key="userJourney._id">
                <td>{{ userJourney.title }}</td>
                <td>{{ userJourney.description }}</td>
                <td>{{ userJourney.importance }}</td>
                <td class="uj-actions">
                  <Button type="secondary" @click="$refs.edituserjourneymodal.show(userJourney._id)" size="micro">{{ $gettext('Edit') }}</Button>
                  <Button type="serious" size="micro">{{ $gettext('Archive') }}</Button>
                </td>
              </tr>
            </DataTable>
          </FormFieldSet> -->
        </template>
      </form>
      <span v-show="hasPermission('Restrictions','CanNotEditReportSettings')">
        {{$gettext("You do not have permissions to alter these settings.")}}
      </span>
    </Body>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters, mapActions } from 'vuex';
import UIBody from '@/components/UI/Body';
import UIButtonSet from '@/components/UI/ButtonSet';
import UINotice from '@/components/UI/Notice';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormFieldSet from '@/components/Form/FieldSet';
import TextArea from '@/components/Form/TextArea';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaListBox from '@/components/Aria/ListBox';
import Button from '@/components/UI/Button.vue';

import config from '../../../config';

export default {
  name: 'ReportsSettingsView',
  data() {
    const portfolioTeamTabs = [
      {
        text: "Portfolio",
        controls: "tabpane",
        action: () => {
          if( !this.tabbedToPortfolio ) {
            this.tabbedToPortfolio = true;
          }
        },
      },
      {
        text: "Team",
        controls: "tabpane",
        action: () => {
          if( this.tabbedToPortfolio ) {
            this.tabbedToPortfolio = false;
          }
        },
      },
    ];

    return {
      identifier: this.$route.params.identifier.replace( '%20', ' ' ),
      userJourneys: false,
      report: false,

      currTeam: '0',
      currPortfolio: '',

      portfoliosOn: false,
      tabbedToPortfolio: false,
      portfolioTeamTabs,
      lastKnownTab: 1,

      reportTemplateChoices: JSON.parse( config.templateChoices ),
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Assessment Settings',
      },
    } );
  },
  watch: {
    currPortfolio() {
      if( this.currPortfolio != '' ) {
        this.$apollo.query( {
          query: gql`
            query Portfolio($id: ObjectID) {
              portfolio: Portfolio(id: $id) {
                _id
                customer {
                  _id
                  name
                }
              },
            }
          `,
          variables:  {
            id: this.currPortfolio,
          },
        } ).then( res => {
          this.$refs.customer.selectWithoutFocus( res.data.portfolio.customer._id, res.data.portfolio.customer.name );
          this.report.customer = {
            _id: res.data.portfolio.customer._id,
            name: res.data.portfolio.customer.name,
          };
        } );
      }
    },
  },
  apollo: {
    report: {
      query: gql`
        query Report($ident: String!) {
          report: Report(identifier: $ident) {
            _id,
            identifier,
            title,

            productAliases,
            productDescription,
            productUrl,
            productStatementUrl,

            productRisk,
            productRiskDescription,

            disproportionateBurden,
            disproportionateBurdenDescription,

            accessibilityStatement,
            accessibilityStatementNotes,

            anonymizeEmails
            anonymizeNames

            sensitive
            internalTeamAccess

            template

            currentVersion {
              _id
            }

            customer {
              _id,
              name
            },
            owner {
              _id,
              name
              email
            },
            collaborators {
              _id,
              name,
              email
            }
            team {
              _id,
              name
            }
            portfolio {
              _id
              identifier
              name
            }
          }
        }
      `,
      variables() {
        return {
          ident: this.identifier,
        };
      },
      update( data ) {
        if( data.report.team ) {
          this.currTeam = data.report.team._id;
        }
        if( data.report.portfolio ) {
          this.currPortfolio = data.report.portfolio._id;
          this.tabbedToPortfolio = true;
          this.lastKnownTab = 0;
        }

        this.setReport( data.report.currentVersion._id );

        return { ...data.report };
      },
    },
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
            customer {
              _id
              name
            }
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          if( team.customer ) {
            opts[team._id] = { label: team.name, group: team.customer.name };
          } else {
            opts[team._id] = team.name;
          }
        }

        return opts;
      },
    },
    portfolios: {
      query: gql`
        {
          portfolios: Portfolios {
            _id
            name
            customer {
              _id
              name
            }
          },
        }
      `,
      update: data => {
        const opts = {};

        for( const portfolio of data.portfolios ) {
          if( portfolio.customer ) {
            opts[portfolio._id] = { label: portfolio.name, group: portfolio.customer.name };
          } else {
            opts[portfolio._id] = portfolio.name;
          }
        }

        return opts;
      },
    },
  },
  created() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            internalTeamAccess
            productDetails
            portfolios
          }
        }
      `,
    } ).then( res => {
      this.productSettings = res.data.settings.productDetails;
      this.portfoliosOn = res.data.settings.portfolios;
      this.internalTeamAccessOn = res.data.settings.internalTeamAccess;
      // if(this.portfoliosOn) {
      //   this.tabbedToPortfolio = true;
      // }
    } );
  },
  methods: {
    ...mapActions( [ 'setReport' ] ),
    submitReport() {
      let team = null;
      let portfolio = null;

      if( this.tabbedToPortfolio ) {
        portfolio = this.currPortfolio != '' ? this.currPortfolio : null;
      } else {
        portfolio = null;
      }

      team = this.currTeam != '0' ? this.currTeam : null;

      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportSettings($id: ObjectID!, $settings: ReportSettingsInput!) {
            report: updateReportSettings(id: $id, settings: $settings) {
              _id
            }
          }
        `,
        variables: {
          id: this.report._id,
          settings: {
            customer: this.report.customer?._id,
            owner: this.report.owner._id,
            collaborators: this.report.collaborators.map( i => i._id ),
            title: this.report.title,
            team,
            portfolio,
            anonymizeEmails: this.report.anonymizeEmails,
            anonymizeNames: this.report.anonymizeNames,
            sensitive: this.report.sensitive,
            internalTeamAccess: this.report.internalTeamAccess,
            template: this.report.template,
          },
        },
      } ).then( () => {
        this.$alerts.success( 'Settings updated', this.$gettext( '%{projectString} settings have been updated', { projectString: this.$hugrConfig.projectStringCap } ) );
      } ).catch( err => {
        if ( err.message.indexOf( 'ACL err' ) >= 0 ) {
          this.$alerts.error( 'Insufficient permissions' );
        } else {
          this.$alerts.error( this.$gettext( '%{projectString} settings not updated', { projectString: this.$hugrConfig.projectStringCap } ), `Please try again later or contact us if this issue persists.` );
        }
        //XXX: roll back?
      } );
    },
    saveProductDetails() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateProductDetails($id: ObjectID!, $productDetails: ProductDetailsInput!) {
            report: updateProductDetails(id: $id, productDetails: $productDetails) {
              _id
            }
          }
        `,
        variables: {
          id: this.report._id,
          productDetails: {
            productAliases: String( this.report.productAliases ).split( ',' ).map( t => t.trim() ),
            productDescription: this.report.productDescription,
            productUrl: this.report.productUrl,
            productStatementUrl: this.report.productStatementUrl,
          },
        },
      } ).then( () => {
        this.$alerts.success( 'Product details updated.', `Product details have been updated` );
        this.$apollo.queries.report.refetch();
      } );
    },
    saveProductRisk() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateProductRisk($id: ObjectID!, $productRisk: ProductRiskInput!) {
            report: updateProductRisk(id: $id, productRisk: $productRisk) {
              _id
            }
          }
        `,
        variables: {
          id: this.report._id,
          productRisk: {
            productRisk: this.report.productRisk,
            productRiskDescription: this.report.productRiskDescription,
          },
        },
      } ).then( () => {
        this.$alerts.success( 'Product risk updated.', `Product risk has been updated` );
        this.$apollo.queries.report.refetch();
      } );
    },
    saveDisproportionateBurden() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateDisproportionateBurden($id: ObjectID!, $disproportionateBurden: DisproportionateBurdenInput!) {
            report: updateDisproportionateBurden(id: $id, disproportionateBurden: $disproportionateBurden) {
              _id
            }
          }
        `,
        variables: {
          id: this.report._id,
          disproportionateBurden: {
            disproportionateBurden: this.report.disproportionateBurden,
            disproportionateBurdenDescription: this.report.disproportionateBurdenDescription,
          },
        },
      } ).then( res => {
        this.$alerts.success( 'Disproportionate burden updated.', `Disproportionate burden has been updated` );
        this.$apollo.queries.report.refetch();
      } );
    },
    saveAccessibilityStatement() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateAccessibilityStatement($id: ObjectID!, $accessibilityStatement: AccessibilityStatementInput!) {
            report: updateAccessibilityStatement(id: $id, accessibilityStatement: $accessibilityStatement) {
              _id
            }
          }
        `,
        variables: {
          id: this.report._id,
          accessibilityStatement: {
            accessibilityStatement: this.report.accessibilityStatement,
            accessibilityStatementNotes: this.report.accessibilityStatementNotes,
          },
        },
      } ).then( res => {
        this.$alerts.success( 'Accessibility statement updated.', `Accessibility statement has been updated` );
        this.$apollo.queries.report.refetch();
      } );
    },
    setCustomer( customer, display ) {
      this.$refs.customer.select( customer, display );
      this.report.customer = {
        _id: customer,
        name: display,
      };
    },
    setOwner( owner, display ) {
      this.$refs.owner.select( owner, display );
      this.report.owner = {
        _id: owner,
        name: display,
      };
    },
    setTeam( team, display ) {
      this.$refs.team.select( team, display );
      this.currTeam = team;
      this.report.team = {
        _id: team,
        name: display,
      };
    },
    addCollaborator( collaborator ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          id: collaborator,
        },
      } ).then( res => {
        const collaborators = [ ...this.report.collaborators ];
        collaborators.push( res.data.user );
        this.report.collaborators = collaborators;
      } );
    },
    rmCollaborator( collaborator ) {
      const collaborators = [ ...this.report.collaborators ];
      collaborators.remove( collaborator );
      this.report.collaborators = collaborators;
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission', 'hasPermissions' ] ),
  },
  components: {
    Body: UIBody,
    ButtonSet: UIButtonSet,
    Notice: UINotice,
    TextArea,
    AriaSearchSelect,
    AriaListBox,
    FormInput,
    FormFieldSet,
    FormSelect,
    Button,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

.Settings {
  &_Body {
    &_Form {
      &_Actions {
        float: right;
      }
    }
  }
}

.TeamPortfolioTabList {
  padding-bottom: 0 !important;
}

#tabpane {
  // background: $hugr-colours-grey;
  border: 1px solid $hugr-colours-grey;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;

  margin-bottom: 50px;

  fieldset {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.uj-actions {
  button {
    margin: 0 0.25rem;
  }
}
</style>
