<template>
  <Widget title="Critical Issues" class="CriticalIssuesWidget" nominalWidth="6" height="3">
    <ul class="CriticalIssuesWidget_List">
      <li class="CriticalIssuesWidget_List_Item" v-for="issue in statistics.criticalIssues" :key="issue.id">
        <span class="CriticalIssuesWidget_List_Item_Inner" @click="toggleIssue( issue )">
          <Icon type="solid" :icon="showing( issue) ? 'chevron-up' : 'chevron-down'" />
          <button class="CriticalIssuesWidget_List_Item_Inner_Title" :aria-expanded="showing( issue)">{{ issue.template.identifier }} {{ issue.template.title }}</button>
          <span class="CriticalIssuesWidget_List_Item_Inner_Instances">&nbsp;{{ issue.instanceCount }} {{ issue.instanceCount==1?'instance':'instances' }}</span>
        </span>
        <ul class="CriticalIssuesWidget_List_Item_Instances" v-show="showing( issue )">
          <li class="CriticalIssuesWidget_List_Item_Instances_Instance" v-for="instance in issue.instances" :key="instance._id">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${instance.reportVersion.report.identifier}/${instance.reportVersion.version}/issues/${instance.identifier}`">{{ instance.identifier }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </Widget>
</template>

<script setup>
  import { ref, toRefs, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      criticalIssues {
        template {
          _id
          identifier
          title
        }
        instanceCount
        instances {
          _id
          identifier
          reportVersion {
            _id
            version
            report {
              _id
              identifier
            }
          }
        }
      }
    }`;

  const props = defineProps( {
    customer: String,
  } );
  const { customer } = toRefs( props );

  const query = ref( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  if( customer.value ) {
    query.value = gql`
    query Customer( $id: ObjectID! ) {
      me: Customer( id: $id ) {
        _id
        ${statsgql}
      }
    }
  `;
  }

  const { onResult, refetch, onError } = useQuery( query,
    {
      id: customer.value,
    },
  );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const show = ref( [] );

  const toggleIssue = issue => {
    const index = show.value.indexOf( issue.template._id );
    if( index >= 0 ) show.value.splice( index, 1 );
    else show.value.push( issue.template._id );
  };

  const showing = issue => show.value.indexOf( issue.template._id ) >= 0;
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .CriticalIssuesWidget {
    &_List {
      list-style: none;
      padding: 0;
      &_Item {
        padding: 6px;
        border-bottom: 1px solid $hugr-colours-grey;
        &_Inner {
          font-size: 0.8em;
          button {
            background: transparent;
            border: none;
            font-weight: bold;
          }
          &_Instances {
            font-weight: bold;
            float: right;
          }
        }

        &_Instances {
          list-style: none;
          padding-left: 8px;
          &_Instance {

          }
        }
      }
    }
  }
</style>
