<template>
  <Modal
    ref="modal"
    :title="$gettext('Dev Modal')"

    :showActions="false"

    @close="reset()">

    <h2>Alerts</h2>
    <Button size="micro" @click="fireSuccess">Fire success</Button>
    <Button size="micro" @click="fireError">Fire error</Button>
    <Button size="micro" @click="fireCodedError">Fire coded error</Button>
    <Button size="micro" @click="fireInfo">Fire alert</Button>
  </Modal>
</template>

<script>
  export default {
    name: "DevModal",
  };
</script>

<script setup>
  import { ref, inject } from 'vue';
  
  const alerts = inject( 'alerts' );

  const modal = ref( null );

  const fireSuccess = () => alerts.success( 'This is a success', `This is a success body text and <a href="#">link</a>` );
  const fireError = () => alerts.error( 'This is an error', `This is an error body text and <a href="#">link</a>` );
  const fireCodedError = () => alerts.coded( 'E001', 'F001' );
  const fireInfo = () => alerts.info( 'This is info', `This is an info message` );

  const show = () => {
    modal.value.show();
  };

  const reset = () => {
    modal.value.reset();
  };

  defineExpose( {
    show,
    reset,
  } );
</script>
