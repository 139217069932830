<template>
  <Widget :title="$gettext( `%{project}s`, { project: $hugrConfig.projectStringCap } )" :hideTitle="true" class="ProjectsWidget" nominalWidth="2">
    <Pie class="ProjectsWidget_Pie" :data="pieData" :cardMode="true" keyStyle="dots" size="micro" :centerKey="$gettext( `%{project}s`, { project: $hugrConfig.projectString } )" :enableCenterKey="false" :enableHeadingKey="true"/>
  </Widget>
</template>

<script setup>
  import { ref, toRefs, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  import Pie from '@/components/UI/Pie';

  const alerts = inject( 'alerts' );

  const props = defineProps( {
    customer: String,
  } );
  const { customer } = toRefs( props );

  const query = ref( gql`
    query Reports {
      projects: Reports {
        _id
        title
        currentVersion {
          _id
          isCompliant
        }
      }
    }
  ` );

  if( customer.value ) {
    query.value = gql`
    query ReportsFromCustomer( $customer: ObjectID ) {
      projects: ReportsFromCustomer( customer: $customer ) {
        _id
        title
        currentVersion {
          _id
          isCompliant
        }
      }
    }
  `;
  }

  const { onResult, refetch, onError } = useQuery( query,
    {
      customer,
    },
   );

  const projects = ref( false );

  onResult( ( { data } ) => {
    if( data ) projects.value = data.projects;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const pieData = computed( () => {
    // console.log( 'making pie data', portfolios.value );
    if( !projects.value ) return [];

    return [
      {
        key: 'Compliant',
        value: projects.value.filter( p => p.isCompliant ).length,
        colour: '#00a648',
      },
      {
        key: 'Not Compliant',
        value: projects.value.filter( p => !p.isCompliant ).length,
        colour: '#fe3246',
      },
    ];
  } );
</script>

<style lang="scss" scoped>

</style>
