<!-- eslint-disable vue/no-use-computed-property-like-method -->
<template>
  <Modal
    ref="modal"
    :stepsInHeader="true"
    :currentStep="currentStep"
    :totalSteps="totalSteps"
    :title="$gettext(getTitle(currentStep))"
    :subtitle="$gettext(getSubtitle(currentStep))"

    :showActions="true"

    emptyText="No data to export"
    :canContinue="stepReady()"
    @continue="nextStep"
    @back="previousStep"
    continueText="Next"
    :completed="completed"

    :loading="exporting"
    loadingText="Exporting..."

    :submitText="$gettext('Export')"
    @submit="nextStep"

    @close="reset()">
    <div v-if="currentStep == 1 && gotData && results.length>0">
      <FilterBar ref="filterBar" class="Issues_Filters Pad_Filter" @clear="clearAllFilters" :filters="filters">
        <FilterOpt :title="$gettext('Issue severity')" :default="filters.severity" @update="(v) => { filters.severity = v }"
          @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
            '0': $gettext('Advisory'),
            '1': $gettext('Low'),
            '2': $gettext('Medium'),
            '3': $gettext('High'),
            '4': $gettext('Critical'),
          }" :extra="{
            '0': $gettext(`Issues that should be addressed to improve the user experience`),
            '1': $gettext(`There may be a minor impact for some users`),
            '2': $gettext(`Some people may have difficulty using the product`),
            '3': $gettext(`Issues that cause severe difficulties for some groups of people`),
            '4': $gettext(`Issues that make the product unusable`),
          }" />
        <FilterOpt :title="$gettext('Issue priority')" @update="(v) => { filters.priority = v }"
          @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
            '0': $gettext('Lowest'),
            '1': $gettext('Low'),
            '2': $gettext('Medium'),
            '3': $gettext('High'),
            '4': $gettext('Highest'),
          }" :extra="{
            '0': $gettext(`Issues that should be fixed when convenient`),
            '1': $gettext(`Issues that require a fix in the long term`),
            '2': $gettext(`Issues that require a in the short term`),
            '3': $gettext(`Issues that require a fix urgently`),
            '4': $gettext(`Issues that require a fix immediately`),
          }" />
          <FilterOpt ref="filterPageOpt" :title="$gettext('Page')" :default="filters.pages" @update="(v) => { filters.pages = v }"
          @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="pages" />
      </FilterBar>
      <div class="Issues_FauxTable Pad">
        <div class="Issues_FauxTable_Head __grid">
          <input class="Checkbox" @click="toggleAll()" :checked="selectedContains(allPrimaryIssues())" type="checkbox" :id="`check-all`" aria-label="Select all issues" :key="`${selectedIssues.length}-check-all`"/>
          <h4 class="__grid_column_9" aria-hidden="true">Issues</h4>
        </div>
        <ul class="Issues_FauxTable_Templates">
          <li v-for="template in issues" :key="template.template._id" class="Issues_FauxTable_Templates_Item">
            <div class="Issues_FauxTable_Templates_Item_Inner __grid">
              <p class="__grid_column_1">
                <input class="Checkbox" @click="toggleSome( getPrimaryIssues(template.issues) )" :checked="selectedContains( getPrimaryIssues(template.issues) )" type="checkbox" :aria-label="`Select all ${template.template.identifier} issue instances`" :key="`${selectedIssues.length}-check-template-${template.issues.length}`"/>
              </p>
              <p class="__grid_column_12">
                <button class="Issues_FauxTable_Templates_Item_Inner_Btn" :aria-label="`${template.open?'Collapse':'Expand'} ${template.template.identifier}: ${template.template.title}`" :title="template.open?'Collapse':'Expand'" @click="template.open=!template.open" :aria-expanded="template.open?'true':'false'">
                  <Icon type="solid" :icon="template.open?'chevron-up':'chevron-down'"></Icon>
                </button>
                <span class="Issues_FauxTable_Templates_Item_Inner_TemplateTitle" @click="template.open=!template.open">{{ template.template.identifier }}: {{ template.template.title }}</span>
              </p>
            </div>
            <div class="Issues_FauxTable_Templates_Item_Sub" v-show="template.open">
              <div class="Issues_FauxTable_Templates_Item_Sub_Head __grid" aria-hidden="true">
                <p class="__grid_column_1"></p>
                <h4 class="__grid_column_2" aria-hidden="true">Instance</h4>
                <h4 class="__grid_column_6" aria-hidden="true">Description</h4>
                <h4 class="__grid_column_2" aria-hidden="true">Found on</h4>
                <h4 class="__grid_column_1" aria-hidden="true">Priority</h4>
                <h4 class="__grid_column_1" aria-hidden="true">Severity</h4>
              </div>
              <ul class="Issues_FauxTable_Templates_Item_Sub_Issues">
                <template v-for="issue in template.issues" :key="issue._id">
                  <li v-if="issue.primary" class="Issues_FauxTable_Templates_Item_Sub_Issues_Item">
                    <div class="Issues_FauxTable_Templates_Item_Sub_Issues_Item_Inner __grid">
                      <input class="Checkbox __grid_column_1" v-model="selectedIssues" :value="issue" type="checkbox" :id="`${issue._id}-input`" :aria-label="`Select ${issue.identifier}`" :key="`${issue._id}-check-${selectedIssues.length}`"/>
                      <p class="__grid_column_2 _identifier">
                        <router-link :to="`/reports/${$router.currentRoute.value.params.identifier}/${version}/issues/${issue.identifier}`" @click.prevent="issuefragment.show( issue.identifier )" :title="issue.identifier">{{ issue.identifier }}</router-link>
                      </p>
                      <p class="__grid_column_6 _identifier">
                        <span class="a11yhide">Description: </span>
                        <span>
                            <span>{{ issue.reason }}</span>
                        </span>
                      </p>
                      <p class="__grid_column_2">
                        <span class="a11yhide">Found on: </span>
                        <span>
                          <template v-if="issue.page">
                            <span>{{ issue.page.name }}</span>
                            <span v-if="issue.others.length">,&nbsp;</span>
                            <span>{{ issue.others.map( i => i.page.name ).join( ', ' ) }}</span>
                          </template>
                          <template v-if="issue.component">
                            <span>{{ issue.component.identifier }}</span>
                          </template>
                        </span>
                      </p>
                      <p class="__grid_column_1">
                        <span class="a11yhide">Priority: </span>
                        <Pill :text="getPriority( issue.priority )" size="small" />
                      </p>
                      <p class="__grid_column_1">
                        <span class="a11yhide">Severity: </span>
                        <Pill :text="getSeverity( issue.template.severity )" size="small" />
                      </p>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="currentStep == 1 && gotData && results.length==0">
      <p>We couldn't find any associated issues. Please check to make sure issues are present. </p>
    </div>
    <div v-if="currentStep == 2">
      <div class="Issues_FauxTable_Templates_Item_Sub_Head Border_Thick Pad __grid" aria-hidden="true">
        <h4 class="__grid_column_1">Include?</h4>
        <h4 class="__grid_column_5" aria-hidden="true">Attribute</h4>
        <h4 class="__grid_column_6" aria-hidden="true">Substitute header name</h4>
      </div>
      <div class="Pad Border_Thin __grid" v-for="opt in Object.keys(opts)" :key="opt">
        <input :id="opt" class="Checkbox __grid_column_1" type="checkbox" v-model="opts[opt]">
        <label :for="opt" class="__grid_column_5">
          <span>{{ opt }}</span>
        </label>
        <input class="__grid_column_6" :id="`optionAlternative-${opt}`" type="text" v-model="replacementOptions[opt]" :aria-label="`Alternative header name for ${opt}`"/>
      </div>
      <!-- <div class="Pad Border_Thin __grid" >
        <input id="screenshots-included" class="Checkbox __grid_column_1" type="checkbox" v-model="exportScreenshots">
        <label for="screenshots-included" class="__grid_column_5">
          <span >{{ $gettext('Include screenshots in export') }}</span>
        </label>
      </div> -->
    </div>
    <div class="Pad" v-if="currentStep == 3">
      <div v-if="!exporting">
        <p><b>{{ selectedIssues.length }}</b> Issues selected</p>
        <p><b>{{ getTrueOpts().length }}</b> attributes selected</p>
        <p v-translate><b>What happens next?</b></p>
        <ul>
          <li>A record of this export will be added to the test activity log in Hugr</li>
          <li>You can then import the CSV file to your chosen issue management tool e.g. Jira.</li>
          <li>If you have chosen to include images in this export, remember these will need to be uploaded manually to the relevant tickets in Jira once they are created.</li>
          <li>Once the issues are actioned, remember to reflect this in Hugr by marking the relevant issue instance as a pass.</li>
        </ul>
      </div>
      <div v-else>
        <p><b>{{ selectedIssues.length }}</b> Issues selected</p>
        <p><b>{{ getTrueOpts().length }}</b> attributes selected</p>
        <div v-if="!completed">
          <p v-translate><b>Currently exporting, please wait.</b></p>
          <p><b>{{ fetchedEvidence }}</b> currently fetched out of <b>{{ fetchedEvidence }}</b> </p>
        </div>
        <div>
          <p v-translate><b>Exporting completed.</b></p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
// import FormSelect from '@/components/Form/Select';
import FilterBar from '@/components/UI/FilterBar.vue';
import FilterOpt from '@/components/UI/FilterOpt.vue';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Pill from '@/components/UI/Pill.vue';

export default {
  name: 'CSVReportModal',
  data() {
    return {
      id: false,
      version: false,
      data: false,
      exporting: false,
      completed: false,

      currentStep: 1,
      totalSteps: 3,

      filterBar: false,
      filters: {
        severity: [],
        priority: [],
        pages: [],
      },

      replacementOptions: {
        id: "",
        identifier: "",
        "Issue Template": "",
        "Template Description": "",
        Severity: "",
        Priority: "",
        Page: "",
        Component: "",
        "Reporter Name": "",
        "Reporter Email": "",
        Solutions: "",
        Instances: "",
        Reason: "",
        Evidence: "",
        Status: "",
        Created: "",
      },

      exportScreenshots: false,
      fetchedEvidence: 0,

      issues: false,
      issuesByTemplate: [],
      selectedIssues: [],

      opts: {
        id: false,
        identifier: true,
        "Issue Template": true,
        "Template Description": true,
        Severity: true,
        Priority: true,
        Page: true,
        Component: true,
        "Reporter Name": true,
        "Reporter Email": true,
        Solutions: false,
        Instances: true,
        Reason: true,
        Evidence: true,
        Status: true,
        Created: true,
      },
      results: [],
      gotData: false,
    };
  },
  watch: {
    filters: {
      handler( oldValue, newValue ) {
        if ( this.data.issues ) {
          this.filterIssues();
        }
      },
      deep: true,
    },
  },
  methods: {
    clearAllFilters() {
      this.filters = {
        severity: [],
        priority: [],
      };
    },
    allPrimaryIssues() {
      const ret = [];

      Object.values( this.issues ).forEach( template => {
        template.issues.forEach( issue => {
          if ( issue.primary ) {
            ret.push( issue );
          }
        } );
      } );

      return ret;
    },
    toggleSome( input ) {
      if ( this.selectedContains( input ) ) {
        input.forEach( issue => {
        if ( this.selectedIssues.indexOf( issue ) >= 0 ) {
          this.selectedIssues.splice( this.selectedIssues.indexOf( issue ), 1 );
        }
      } );
      } else {
        input.forEach( issue => {
          if ( this.selectedIssues.indexOf( issue ) < 0 ) {
            this.selectedIssues.push( issue );
          }
        } );
      }
    },
    toggleAll() {
      this.toggleSome( this.allPrimaryIssues() );
    },
    previousStep() {
      if ( this.currentStep > 1 ) {
          this.currentStep--;
      }
    },
    nextStep() {
      if ( this.currentStep == 3 ) {
        this.exporting = true;
        this.exportCsv();
      } else if ( this.currentStep < this.totalSteps ) {
        this.currentStep++;
      }
    },
    stepReady() {
      if ( this.currentStep == 1 ) {
        return this.selectedIssues.length > 0;
      }
      if ( this.currentStep == 2 ) {
        return this.getTrueOpts().length > 0;
      }
      if ( this.currentStep == 3 ) {
        return true;
      }

      return false;
    },
    async fetchScreenshots( ) {
      const zip = new JSZip();

      this.selectedIssues.forEach( issue => {
        let img = 1;
        this.fetchedEvidence++;
        issue.evidence.forEach( async evidence => {
          const extension = "png";
          let blob = false;

          fetch( `${this.$hugrConfig.assetsUrl}/evidence/${evidence}` ).then( r => {
            if ( r.status === 200 ) return r.blob();

            return Promise.reject( new Error( r.statusText ) );
          } ).then( data => {
            blob = data;
          } );

          zip.folder( `${issue.identifier}` ).file( `${issue.identifier}-${img}.${extension}`, blob );
          img++;
        } );
      } );

      zip.generateAsync( { type:"blob" } ).then( blob => {
        saveAs( blob, "screenshots.zip" );
      } );
    },
    getTitle( step ) {
      switch( step ) {
        case 1: {
          return "Select issues to export";
        }
        case 2: {
          return "Select attributes to include";
        }
        case 3: {
          return "Confirm CSV export";
        }
        default: {
          return "CSV export";
        }
      }
    },
    getSubtitle( step ) {
      switch( step ) {
        case 1: {
          return "Choose which issues you would like to include in your CSV export";
        }
        case 2: {
          return "Choose what information you would like to include against each issue";
        }
        case 3: {
          return "";
        }
        default: {
          return "CSV export";
        }
      }
    },
    getSeverity( severity ) {
    switch( severity ) {
      case 0: {
        return 'Advisory';
      }
      case 1: {
        return 'Low';
      }
      case 2: {
        return 'Medium';
      }
      case 3: {
        return 'High';
      }
      case 4: {
        return 'Critical';
      }
      default: {
        return 'Unknown';
      }
    }
    },
    getPriority( priority ) {
    switch( priority ) {
      case 0: {
        return 'Lowest';
      }
      case 1: {
        return 'Low';
      }
      case 2: {
        return 'Medium';
      }
      case 3: {
        return 'High';
      }
      case 4: {
        return 'Highest';
      }
      default: {
        return 'Unknown';
      }
    }
    },
    filterIssues() {
    let ret = this.data.issues.filter( i => i.status.indexOf( 'closed' ) != 0 );

    if( this.filters && this.filters.severity && this.filters.severity.length ) {
      ret = ret.filter( i => {
        return this.filters.severity.indexOf( i.template.severity.toString() ) >= 0;
      } );
    }

    if( this.filters && this.filters.priority && this.filters.priority.length ) {
      ret = ret.filter( i => {
        return this.filters.priority.indexOf( i.priority.toString() ) >= 0;
      } );
    }
    
    if( this.filters && this.filters.pages && this.filters.pages.length ) {
      ret = ret.filter( i => {
        let otherMatch = false;
        if ( i.others ) {
          i.others.forEach( other => {
            if ( this.filters.pages.indexOf( other.page._id.toString() ) >= 0 ) {
              otherMatch = true;
            }
          } );
        }
        
        return ( i.page && this.filters.pages.indexOf( i.page._id.toString() ) >= 0 ) || otherMatch;
      } );
    }

    const issuesByTemplate = {};

    for( const issue of ret ) {
      if( typeof issuesByTemplate[ issue.template._id ] == 'undefined' ) issuesByTemplate[ issue.template._id ] = { template: issue.template, issues: [], open: false, key: false };
      if( issue.isAKeyIssueInstance ) issuesByTemplate[ issue.template._id ].key = true;
      issuesByTemplate[ issue.template._id ].issues.push( issue );
    }

    this.issues = issuesByTemplate;

    },
    getTrueOpts() {
      const ret = [];
      
      Object.entries( this.opts ).forEach( opt => {
        if ( opt[1] == true ) {
          ret.push( opt );
        }
      } );

      return ret;
    },
    getPrimaryIssues( template ) {
      const ret = [];
      
      template.forEach( issue => {
        if ( issue.primary ) {
          ret.push( issue );
        }
      } );

      return ret;
    },
    selectedContains( input ) {
      const ret = [];

      input.forEach( item => {
        if ( this.selectedIssues.indexOf( item ) >= 0 ) {
          ret.push( item );
        }
      } );

      return input.length == ret.length;
    },
    async exportCsv() {
      if ( this.exportScreenshots ) {
        await this.fetchScreenshots();
      }

      const rows = [];

      const headers = [];
      for( const opt of Object.keys( this.opts ) ) {
        if( this.opts[opt] ) {
          if ( this.replacementOptions[opt].length > 0 ) {
            headers.push( this.replacementOptions[opt] );
          } else {
            headers.push( opt );
          }
        }
      }
      rows.push( headers );

      const toExport = [];

      for( const issue of this.selectedIssues ) {
          if( issue.primary ) {
            toExport.push( {
              id: issue._id,
              identifier: issue.identifier,
              "Issue Template": `${issue.template.identifier}: ${issue.template.title}`,
              "Template Description": `${issue.template.description}`,
              Severity: this.SeverityString( parseInt( issue.template.severity ) ),
              Priority: this.priorityString( parseInt( issue.priority ) ),
              Page: issue.page ? issue.page.name + issue.others.map( i => i.page.name ).join( ', ' ) : 'none',
              Component: issue.component ? issue.component.identifier : 'none',
              "Reporter Name": issue.reporter.displayname && issue.reporter.displayname != "" ? issue.reporter.displayname : issue.reporter.name,
              "Reporter Email": issue.reporter.email,
              Solutions: issue.solutions.map( s => `${s.identifier}: ${s.title}` ).join( ', ' ),
              Instances: issue.others.length + 1,
              Reason: issue.reason,
              Evidence: issue.evidence.map( e => `${this.$hugrConfig.assetsUrl}/evidence/${e}` ).join( ', ' ),
              Status: issue.status,
              Created: issue.timestamp,
            } );
          }
        }

      for( const issue of toExport ) {
        const row = [];

        for( const opt of Object.keys( this.opts ) ) {
          if( this.opts[opt] ) {
            row.push( issue[opt] );
          }
        }

        rows.push( row );
      }

      const csvContent = `data:text/csv;charset=utf-8,${
         rows.map( e => e.map( i => {
          if( typeof i == 'string' ) {
            return `"${i.replace( /"/g, '\\"' )}"`;
          }

            return `"${i}"`;

        } ).join( "," ) ).join( "\n" )}`;

        this.completed = true;

        const encodedUri = encodeURI( csvContent ).replaceAll( '#', '%23' );
        window.open( encodedUri );
        this.exporting = false;

        this.$apollo.mutate( {
          mutation: gql`
            mutation csvExportToActivityLog( $reportVersion: ObjectID! ) {
              csvExportToActivityLog( reportVersion: $reportVersion )
            }
          `,
          variables: {
            reportVersion: this.id,
          },
        } );
    },
    getData() {
      this.$apollo.query( {
        query: gql`
          query ReportFromVersion($id: ObjectID!) {
            report: ReportFromVersion(id: $id) {
              _id
              title
              
              issues {
                _id
                identifier
                isAKeyIssueInstance
                flag

                template {
                  _id
                  title
                  description
                  identifier
                  title
                  severity
                }

                page {
                  _id
                  name
                }

                others {
                  page {
                    _id,
                    name
                  }
                }

                component {
                  _id
                  identifier
                }
                reporter {
                  _id
                  name
                  displayname
                  email
                }
                solutions {
                  _id
                  identifier
                  title
                }
                others {
                  _id
                }

                reason
                priority
                evidence
                status
                primary
                timestamp
              }
            }
          }
        `,
        variables: {
          id: this.id,
        },
      } ).then( res => {
        this.data = res.data.report;
        const results = [];
        this.filterIssues();
        for( const issue of this.data.issues ) {
          if( issue.primary ) {
            results.push( {
              id: issue._id,
              identifier: issue.identifier,
              "Issue Template": `${issue.template.identifier}: ${issue.template.title}`,
              "Template Description": `${issue.template.description}`,
              Severity: this.SeverityString( parseInt( issue.template.severity ) ),
              Priority: this.priorityString( parseInt( issue.priority ) ),
              Page: issue.page ? issue.page.name + issue.others.map( i => i.page.name ).join( ', ' ) : 'none',
              Component: issue.component ? issue.component.identifier : 'none',
              "Reporter Name": issue.reporter.displayname && issue.reporter.displayname != "" ? issue.reporter.displayname : issue.reporter.name,
              "Reporter Email": issue.reporter.email,
              Solutions: issue.solutions.map( s => `${s.identifier}: ${s.title}` ).join( ', ' ),
              Instances: issue.others.length + 1,
              Reason: issue.reason,
              Evidence: issue.evidence.map( e => `${this.$hugrConfig.assetsUrl}/evidence/${e}` ).join( ', ' ),
              Status: issue.status,
              Created: issue.timestamp,
            } );
          }
        }
        this.gotData = true;
        this.results = results;
      } ).catch( () => {
        this.$alerts.coded( 'E025', 'F2801' ); //see notifications spreadsheet
      } );
    },
    showByPage( id, version, page ) {
      this.filters.pages.push( page );
      this.show( id, version );
    },
    show( id, version ) {
      this.id = id;
      this.version = version;
      this.getData();
      this.$refs.modal.show();
      this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Assessment CSV Export',
      },
      } );
    },
    reset() {
      this.id = false;
      this.data = false;
      this.completed = false;
      this.exporting = false;
      this.filters = {
        severity: [],
        priority: [],
        pages: [],
      };
      this.filterBar = false;
      this.selectedIssues = [];
      this.currentStep = 1;
      this.results = [];
      this.$refs.modal.reset();
    },

    SeverityString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case 0: {
          ret = 'Advisory';

          break;
        }
        case 1: {
          ret = 'Low';

          break;
        }
        case 2: {
          ret = 'Medium';

          break;
        }
        case 3: {
          ret = 'High';

          break;
        }
        case 4: {
          ret = 'Critical';

          break;
        }
      }

      return this.$gettext( ret );
    },
    priorityString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case 0: {
          ret = 'Lowest';

          break;
        }
        case 1: {
          ret = 'Low';

          break;
        }
        case 2: {
          ret = 'Medium';

          break;
        }
        case 3: {
          ret = 'High';

          break;
        }
        case 4: {
          ret = 'Highest';

          break;
        }
      }

      return this.$gettext( ret );
    },
  },
  computed: {
    pages() {
      if( !this.data.issues ) return;
    
      const res = {};
      for( const issue of this.data.issues ) {
        if( issue.page ) {
          res[issue.page._id] = issue.page.name;
        }
        if ( issue.others ) {
          issue.others.forEach( i => {
            res[i.page._id] = i.page.name;
          } );
        }
      }

      return res;
    },
  },
  components: {
    FilterBar,
    FilterOpt,
    Pill,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';

  .Checkbox {
    width: 16px;
    height: 16px;
    align-self: center;
    accent-color: #202837;
  }

  .Pad {
    padding: 0px 16px;
    &_Filter {
      padding: 8px;
    }
  }

  .Border {
    &_Thick {
      border-bottom: 4px solid #202837;
      margin-bottom: 8px;
    }
    &_Thin {
      border-bottom: 1px solid #616671;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }
  }

  .__grid {
    grid-template-columns: repeat(13,1fr);
    align-items: center;
  }

  .actions {
    display: flex !important;
    justify-content: space-between !important;
    background: #EBEDF0;
    padding-top: 8px;
  }

  .Issues {
    position: relative;

    &_Blurb {
      font-size: 0.9em;
    }

    &_Btns {
      position: absolute;
      right: 50px;
      top: 36px;

      button, a {
        margin-left: 8px;
      }
    }

    &_FilterAlert {
      font-family: $hugr-fonts-body;
      font-weight: bold;
      font-size: 0.9em;
    }
    
    &_FauxTable {
      &_Head {
        border-bottom: 4px solid #202837;
        h4 {
          font-family: $hugr-fonts-logo;
          font-weight: bold;
          margin: 0;
          text-align: left;
          padding: 8px;
          padding-left: 0;
          padding-right: 16px;

          &:first-child {
            margin-left: 21px;
          }
        }
      }

      &_Templates {
        list-style: none;
        padding: 0;
        margin: 0;
        min-height: 500px;
        padding-bottom: 16px;
        &_Item {
          &_Inner {
            border-bottom: 1px solid #616671;
            &_Btn {
              border: none;
              margin: 0;
              background: transparent;

              padding: 4px;
              cursor: pointer;
            }
            &_TemplateTitle {
              text-decoration: underline;
              color: $hugr-colours-new-link;
              cursor: pointer;
            }
            p {
              display: table;
              margin: 0;
              padding: 0.8em 0;
              font-size: 0.8em;
              span {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
              }
            }
          }

          &_Sub {
            background: $hugr-colours-input-surface;
            &_Head {
              h4 {
                font-family: "Quicksand", sans-serif;
                font-size: 10pt;
                font-weight: bold;
                margin: 0;
                padding: 0.8em 0;
                color: $hugr-colours-primary;
              }
            }
            &_Issues {
              list-style: none;
              padding: 0;
              margin: 0;
              &_Item {
                // &:nth-child(odd) {
                //   background: lighten( $hugr-colours-grey, 14% );
                // }
                &_Inner {
                  p {
                    display: table;
                    margin: 0;
                    padding: 0.8em 0;
                    font-size: 0.8em;
                     > span {
                      display: table-cell;
                      vertical-align: middle;
                      width: 100%;
                    }
                  }
                  ._identifier {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ._darkMode .Issues {
    &_FauxTable {
      &_Templates {
        &_Item {
          &_Sub {
            background: darken( $hugr-colours-primary, 10% );
            &_Head {
              h4 {
                color: #FFF;
              }
            }
            &_Issues {
              &_Item {
                // &:nth-child(odd) {
                //   background: lighten( $hugr-colours-primary, 20% );
                // }
              }
            }
          }
          &_Inner {
            &_Btn {
              color: #FFF;
            }
            &_TemplateTitle {
              color: $dig-orange;
            }
          }
        }
      }
    }
  }

  ._darkMode .actions {
    background: darken( $hugr-colours-primary, 10% );
  }
</style>
