<template>
    <Body :title="$gettext(`Permission Group Management`)" :noPadBottom="true" v-if="hasPermission( 'Admin', 'ManagePermissionGroups' )">
      <TabList class="tablistNoPad" v-if="tabs.length" :tabs="tabs" :key="`permissiontabs-${tabs.length}-${showBody}`" />
      <!-- :lastIndex="tabs.map(t => t.text).indexOf(team.name)" /> -->
  <div v-show="showBody=='Groups'" :key="'permission-groups-showing'">
  <PermissionGroupForm @success="$apollo.queries.permissionGroups.refetch()" />

  <div v-if="permissionGroups.length > 0">
    <FormSelect idRoot="permissiongroup_" :label="this.$gettext('Permission Group')" v-model="selectedGroup" :options="niceList" @change="processPermissions()"/>
  </div>
  <div v-else v-translate>
    No Permission Groups currently exist, please make one.
  </div>

  <div v-if="selectedGroup" :key="`permission-groups-showing-CRUD-${selectedGroup}`">
    {{$gettext('CRUD Permissions')}}
    <DataTable class="noTableMinHeight" :manualHead="true" :key="`permission-groups-showing-CRUD-${selectedGroup}-table`">
        <thead>
          <tr>
            <th v-translate>Permission</th>
            <th v-translate>Create</th>
            <th v-translate>Read</th>
            <th v-translate>Update</th>
            <th v-translate>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in parseCRUDPermissions" v-bind:key="'permission-' + permission">
            <td>{{permission}}</td>
            <td>
              <template v-if="CRUDhasPermission( permission, 'c' )">
                <input type="checkbox" :key="permission + '-create-key'" :id="permission + '-create'" :value='PermissionObject(permission,"Create")' v-model="selectedCRUD">
                <label :for="permission + '-create'">{{$gettext('Create')}}</label>
              </template>
            </td>
            <td>
              <template v-if="CRUDhasPermission( permission, 'r' )" >
                <input type="checkbox" :key="permission + '-read-key'" :id="permission + '-read'" :value='PermissionObject(permission,"Read")' v-model="selectedCRUD">
                <label :for="permission + '-read'">{{$gettext('Read')}}</label>
              </template>
            </td>
            <td>
              <template v-if="CRUDhasPermission( permission, 'u' )">
                <input type="checkbox" :key="permission + '-update-key'" :id="permission + '-update'" :value='PermissionObject(permission,"Update")' v-model="selectedCRUD">
                <label :for="permission + '-update'">{{$gettext('Update')}}</label>
              </template>
            </td>
            <td>
              <template v-if="CRUDhasPermission( permission, 'd' )">
                <input type="checkbox" :key="permission + '-delete-key'" :id="permission + '-delete'" :value='PermissionObject(permission,"Delete")' v-model="selectedCRUD">
                <label :for="permission + '-delete'">{{$gettext('Delete')}}</label>
              </template>
            </td>
          </tr>
        </tbody>
      </DataTable>
    {{$gettext('Other Permissions')}}
    <DataTable class="noTableMinHeight" :manualHead="true" :key="`permission-groups-showing-nonCRUD-${selectedGroup}-table`">
        <thead>
          <tr>
            <th v-translate>Permission</th>
            <th v-translate>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in OtherPermissions" v-bind:key="'permission-' + permission">
            <td>{{permission[0]}}</td>
            <td>
              <span v-for="(choice, i) in permission" v-bind:key="'permission-' + permission + i">
                <input v-if="i>0" type="checkbox" :key="permission + '-choice-' + i" :id="permission + '-choice-' + i" :value='PermissionObject(permission[0],permission[i])' v-model="selectedOther"><label v-if="i>0" :for="permission + '-choice-' + i">{{permission[i]}}</label>
              </span>
            </td>
          </tr>
        </tbody>
      </DataTable>
      {{$gettext('Group Category Options')}}
      <div v-if="permissionGroupCategories.length > 0">
      <DataTable class="noTableMinHeight" :manualHead="true" :key="`permission-groups-showing-categories-${selectedGroup}`">
        <thead>
          <tr>
            <th v-translate>Category Name</th>
            <th v-translate>Group in Category?</th>
            <th v-translate>Hierarchy</th>
            <th v-translate>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(permissionGroupCategory, i) in permissionGroupCategories" v-bind:key="'permission-category-' + i">
            <td>{{permissionGroupCategory.name}}</td>
            <td>
              <span v-if="editingGroupInCategory==permissionGroupCategory._id">
                <label :for="permissionGroupCategory._id + '-belongsCheckbox'">{{$gettext('In Category?')}}</label>
                <input class="buttonPad" type="checkbox" :key="permissionGroupCategory._id + '-belongs'" :id="permissionGroupCategory._id + '-belongsCheckbox'" v-model="categoryEditingCheckbox">
              </span>
              <span :key="permissionGroupCategory._id + '-belonging' + isGroupInCategory(permissionGroupCategory._id)" v-else>
                {{isGroupInCategory(permissionGroupCategory._id)}}
              </span>
            </td>
            <td>
              <span v-if="editingGroupInCategory==permissionGroupCategory._id">
                <label :for="permissionGroupCategory._id + '-hierarchySelector'">{{$gettext('Hierarchy')}}</label>
                <input class="buttonPad" type="number" :id="permissionGroupCategory._id + '-hierarchySelector'" :name="permissionGroupCategory._id + '-hierarchySelector'" min="0" max="50" v-model="categoryEditingHierarchy">
              </span>
              <span :key="permissionGroupCategory._id + '-hierarchy' + groupHierarchyInCategory(permissionGroupCategory._id)" v-else>
                {{groupHierarchyInCategory(permissionGroupCategory._id)}}
              </span>
            </td>
            <td>
              <Button v-if="editingGroupInCategory!=permissionGroupCategory._id" type="secondary" size="micro" @click="categoryEditingCheckbox=isGroupInCategory(permissionGroupCategory._id); categoryEditingHierarchy=groupHierarchyInCategory(permissionGroupCategory._id); editingGroupInCategory=permissionGroupCategory._id">{{$gettext('Edit Group in Category')}}</Button>
              <Button v-if="editingGroupInCategory==permissionGroupCategory._id" type="secondary" size="micro" @click="setGroupInCategory(permissionGroupCategory._id)">{{$gettext('Save')}}</Button>
            </td>
          </tr>
        </tbody>
      </DataTable>
    </div>
  </div>

    <form @submit.prevent="updatePermissionGroup" class="Settings_Body_Form">

      <ButtonSet class="">
        <Button type="primary" htype="submit">{{$gettext('Save')}}</Button>
      </ButtonSet>
    </form>
  </div>

  <div v-show="showBody == 'Categories'" :key="'permission-category-showing'">
    <PermissionGroupCategoryForm @success="$apollo.queries.permissionGroupCategories.refetch()" />

    <div v-if="permissionGroupCategories">
    <div v-show="permissionGroupCategories.length > 0">
      <DataTable class="noTableMinHeight" :manualHead="true">
        <thead>
          <tr>
            <th v-translate>Category Name</th>
            <th v-translate>Number of groups</th>
            <th v-translate>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(permissionGroupCategory, i) in permissionGroupCategories" v-bind:key="'permission-category-edit-' + i">
            <td>
              <span v-if="categoryBeingEdited == permissionGroupCategory._id">
                <label :for="permissionGroupCategory._id + '-nameInput'">{{$gettext('New name:')}}</label>
                <input class="buttonPad" :id="permissionGroupCategory._id + '-nameInput'" :name="permissionGroupCategory._id + '-nameInput'" v-model="categoryEditingName">
              </span>
              <span v-else>
                {{permissionGroupCategory.name}}
              </span>
            </td>
            <td>
              {{ groupsInCategory(permissionGroupCategory._id) }}
            </td>
            <td>
              <Button class="buttonPad" v-if="categoryBeingEdited == permissionGroupCategory._id" type="serious" size="micro" @click="categoryBeingEdited = false; categoryEditingName = ''">{{$gettext('Cancel Edit')}}</Button>
              <Button class="buttonPad" v-if="categoryBeingEdited == permissionGroupCategory._id" type="secondary" size="micro" @click="updatePermissionGroupCategoryName(permissionGroupCategory._id)">{{$gettext('Save Category')}}</Button>
              <Button class="buttonPad" v-if="categoryBeingEdited != permissionGroupCategory._id" type="secondary" size="micro" @click="categoryBeingEdited = permissionGroupCategory._id; categoryEditingName = permissionGroupCategory.name;">{{$gettext('Edit Category')}}</Button>
              <Button class="buttonPad" v-if="groupsInCategory(permissionGroupCategory._id) > 0" type="secondary" size="micro" @click="groupsInCategoryList = findGroupsInCategory(permissionGroupCategory._id); categoryBeingListed = permissionGroupCategory._id; $refs.groupsincategorymodal.show()">{{$gettext('List Groups in Category')}}</Button>
              <Button class="buttonPad" v-if="categoryBeingEdited != permissionGroupCategory._id" type="serious" size="micro" @click="deletePermissionGroupCategory(permissionGroupCategory._id)">{{$gettext('Delete Category')}}</Button>
            </td>
          </tr>
        </tbody>
      </DataTable>
    </div>

    <div v-show="permissionGroupCategories.length == 0" v-translate>
      No Permission Group Categories currently exist, please make one.
    </div>
    </div>
    
    </div>
  </Body>
  <GroupsInCategoryModal ref="groupsincategorymodal" :groups="groupsInCategoryList" :categoryID="categoryBeingListed" />
  <br />
  <br />
  <br />
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import PermissionGroupForm from '@/forms/PermissionGroup.vue';
import PermissionGroupCategoryForm from '@/forms/PermissionGroupCategory.vue';

import FormSelect from '@/components/Form/Select';
import GroupsInCategoryModal from '@/modals/GroupsInPermissionCategory';

export default {
  name: 'PermissionGroupsView',
  data() {
    return {
      // CRUD permissions syntax: [PermissionOneName, PermissionTwoName, PermissionThreeName], e.g ["Users", "Tickets", "Teams", "Reports"]
      // Gets auto filled for values: "Create" (alias Write), ""Read", "Update", "Delete".
      CRUDPermissions: [
        "Customers",
        "Teams:cru",
        "Users:cru",
        "Contacts:cru",
        "IssueTemplates:cud",
        "Solutions:cu",
        "SpreadsheetTemplates:cu",
        "Reports:cru",
        "Tickets",
        "Portfolios",
        "ReportTypes",
        "ReportSections:cud",
        "AccessibilityStatementSections:cud",
      ],
      // Other permissions syntax: [ [PermissionOneName, PermissionOneValue1, PermissionOneValue2, ..., PermissioneOnevalue5] ], e.g [ ["AdminPage", "Access", "Edit", "Lock"] ]
      OtherPermissions: [
        [ "Admin", "ManageSettings", "ManagePermissionGroups", "DeveloperOptions", "OverviewDashboard" ],
        [ "AdminPage", "Access", "HideEvenIfAnyPrivileges" ],
        [ "ComplianceDashboard", "Enabled" ],
        [ "AD&I", "UpdateRisk", "UpdateBurden", "UpdateStatement" ],
        [ "Users", "CanInvite" ],
        [ "Contacts", "Archive" ],
        [ "Auth", "EmailMFA" ],
        [ "QualityOfLife", "ExtendedTablePageSize", "SpreadsheetInReportMenu", "AccessibilityStatementInReportMenu", "AdminAll", "PinToDashboard", "QuickCheck" ],
        [ "Automations", "Relevance" ],
        [ "ReportTabs", "Mine", "SharedWithMe", "OnMyTeam", "OnMyPortfolio", "Archive" ],
        [ "SetUserPermissionGroup", "Any", "WithinGroupCategories", "SetLegacyACL" ],
        [ "Pages", "CanDelete" ],
        [ "Components", "CanDelete" ],
        [ "Issues", "CanDelete", "ToggleKeyIssue" ],
        [ "Reports", "Accredit", "Anonymise", "canSetInternalTeamAccess", "NoActiveLimit", "UpdateDetailsSection" ],
        [ "Restrictions", "CanNotQuitTeams", "CanNotEditReportSettings", "CanNotEditReportTitle", "CanNotEditReportTeamOrPortfolio", "CanNotEditReportCollaborators", "CanNotCloneProducts", "CanNotArchiveAndRestoreProducts", "CanNotCreateProducts" ],
        [ "Files", "Upload", "DownloadTeam", "DownloadAll" ],
        [ "SimpleMode", "NoQuickPass", "NoNotApplicable", "NoTodo", "NoNotes" ],
        [ "UIAlts", "IssueBySeverityBar", "ReportPie" ],
        [ "AccessibilityStatementSections", "ToggleActive" ],
        [ "AccessibilityStatementOverrides", "CheckLegalWording", "AssureStatements" ],
        [ "Exports", "CanExportSummary" ],
        [ "Portfolios", "ShowPortfolioPage" ],
      ],
      test: false,
      selectedGroup: "",
      selectedCRUD: [],
      selectedOther: [],
      permissionGroups: [],

      categoryEditingCheckbox: false,
      categoryEditingHierarchy: 0,
      categoryBeingEdited: false,
      categoryEditingName: "",
      editingGroupInCategory: false,
      groupsInCategoryList: [],
      categroyBeingListed: '',

      permissionGroupCategories: false,
      showBody: "Groups",
      tabs: [
        {
          text: "Permission Groups",
          controls: "groups",
          action: () => {
            this.showBody = "Groups";
          },
        },
        {
          text: "Group Categories",
          controls: "categories",
          action: () => {
            this.showBody = "Categories";
          },
        },
      ],
    };
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
            permissions {
              permissionName,
              permissionValue
            },
            categories {
              category {
                _id,
                name
              },
              hierarchy
            }
          }
        }
      `,
    },
    permissionGroupCategories: {
      query: gql`
        query permissionGroupCategories {
          permissionGroupCategories: PermissionGroupCategories {
            _id,
            name
          }
        }
      `,
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    parseCRUDPermissions() {
      return this.CRUDPermissions.map( p => p.split( ':' )[0] );
    },
    niceList() {
      const list = {};
      this.permissionGroups.forEach( group => {
        list[group._id] = group.name;
      } );

      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
    combinedPermissions() {
      const combined = [];

      this.selectedCRUD.forEach( e => {
        combined.push( e );
      } );
      this.selectedOther.forEach( e => {
        combined.push( e );
      } );

      return combined;
    },
  },
  methods: {
    CRUDhasPermission( name, perm ) {
      for( const permission of this.CRUDPermissions ) {
        if( permission.split( ':' )[0] == name && ( typeof permission.split( ':' )[1] == 'undefined' || permission.split( ':' )[1].indexOf( perm ) >= 0 ) ) return true;
      }

      return false;
    },
    PermissionObject( name, value ) {
      return {
        permissionName: name,
        permissionValue: value,
      };
    },
    findGroupsInCategory( categoryId ) {
      const result = [];

      this.permissionGroups.forEach( group => {
        if ( group.categories.filter( cat => cat.category._id == categoryId ).length > 0 ) {
          result.push( group );
        }
      } );

      return result;
    },
    isGroupInCategory( categoryId ) {
      let result = false;
      this.chosenGroup.categories.forEach( pair => {
        if ( pair.category._id == categoryId ) {
          result = true;
        }
      } );

      return result;
    },
    groupHierarchyInCategory( categoryId ) {
      let result = 0;
      this.chosenGroup.categories.forEach( pair => {
        if ( pair.category._id == categoryId ) {
          result = pair.hierarchy;
        }
      } );

      return result;
    },
    groupsInCategory( categoryId ) {
      let count = 0;
      this.permissionGroups.forEach( group => {
        if ( group.categories.filter( cat => cat.category._id == categoryId ).length > 0 ) {
          count++;
        }
      } );

      return count;
    },
    processPermissions() {
      this.selectedCRUD = this.chosenGroup.permissions.filter( permission => {
        return ( permission.permissionValue == "Create" || permission.permissionValue == "Read" || permission.permissionValue == "Update" || permission.permissionValue == "Delete" );
      } );

      this.selectedOther = this.chosenGroup.permissions.filter( permission => {
        return ( permission.permissionValue != "Create" && permission.permissionValue != "Read" && permission.permissionValue != "Update" && permission.permissionValue != "Delete" );
      } );

      const noTypeDefsCRUD = [];
      const noTypeDefsOther = [];

      this.selectedCRUD.forEach( selected => {
        noTypeDefsCRUD.push( { permissionName: selected.permissionName, permissionValue: selected.permissionValue } );
      } );

      this.selectedOther.forEach( selected => {
        noTypeDefsOther.push( { permissionName: selected.permissionName, permissionValue: selected.permissionValue } );
      } );

      this.selectedCRUD = noTypeDefsCRUD;
      this.selectedOther = noTypeDefsOther;
    },
    setGroupInCategory( categoryId ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setPermissionGroupWithCategory($group: ObjectID!, $category: ObjectID!, $inGroup: Boolean!, $hierarchy: Int!) {
            result: setPermissionGroupWithCategory(group: $group, category: $category, inGroup: $inGroup, hierarchy: $hierarchy)
          }
        `,
        variables: {
          group: this.selectedGroup,
          category: categoryId,
          inGroup: this.categoryEditingCheckbox,
          hierarchy: this.categoryEditingHierarchy,
        },
      } ).then( res => {
        this.$alerts.success( this.$gettext( 'Group category settings updated' ), this.$gettext( `The group's category was successfully updated!` ) );
        this.$apollo.queries.permissionGroups.refetch();
        this.editingGroupInCategory = false;
      } ).catch( err => {
        this.$alerts.error( this.$gettext( `Sorry, something went wrong` ), this.$gettext( `We couldn't update the group's category.` ) );
      } );
    },
    deletePermissionGroupCategory( categoryID ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation deletePermissionGroupCategory($id: ObjectID!) {
            result: deletePermissionGroupCategory(id: $id)
          }
        `,
        variables: {
          id: categoryID,
        },
      } ).then( res => {
        this.$alerts.success( this.$gettext( 'Group category deleted' ), this.$gettext( `The group category has been deleted.` ) );
        this.$apollo.queries.permissionGroupCategories.refetch();
      } ).catch( err => {
        this.$alerts.error( this.$gettext( `Sorry, something went wrong` ), this.$gettext( `We couldn't delete the group category.` ) );
      } );
    },
    updatePermissionGroupCategoryName( categoryID ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updatePermissionGroupCategoryName($id: ObjectID!, $name: String!) {
            result: updatePermissionGroupCategoryName(id: $id, name: $name)
          }
        `,
        variables: {
          id: categoryID,
          name: this.categoryEditingName,
        },
      } ).then( res => {
        this.categoryEditingName = "";
        this.categoryBeingEdited = false;
        this.$apollo.queries.permissionGroupCategories.refetch();
        this.$alerts.success( this.$gettext( 'Group category updated' ), this.$gettext( `The group category was successfully updated!` ) );
      } ).catch( err => {
        this.$alerts.error( this.$gettext( `Sorry, something went wrong` ), this.$gettext( `We couldn't update the group category.` ) );
      } );
    },
    updatePermissionGroup() {
      const input = {
        name: this.chosenGroup.name,
        permissions: this.combinedPermissions,
      };

      this.$apollo.mutate( {
        mutation: gql`
          mutation updatePermissionGroup($id: ObjectID!, $permissionGroup: PermissionGroupInput!) {
            group: updatePermissionGroup(id: $id, permissionGroupInput: $permissionGroup) {
              _id,
              name,
              permissions {
                permissionName,
                permissionValue
              }
            }
          }
        `,
        variables: {
          id: this.selectedGroup,
          permissionGroup: input,
        },
      } ).then( res => {
        this.$alerts.success( this.$gettext( 'Group updated' ), this.$gettext( `The group was successfully updated!` ) );
      } ).catch( err => {
        this.$alerts.error( this.$gettext( `Sorry, something went wrong` ), this.$gettext( `We couldn't update the group.` ) );
      } );
    },
  },
  components: {
    PermissionGroupForm,
    PermissionGroupCategoryForm,
    FormSelect,
    GroupsInCategoryModal,
  },
};
</script>
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .noTableMinHeight {
    min-height: 100px;
    margin-bottom: 50px;
  }
  .tablistNoPad {
    padding-bottom: 0px;
  }
  .buttonPad {
    margin-left: 5px;
  }

  .Teams {
    &_Sort {
      position: absolute;
      top: 25px;
      right: 50px;
    }
    &_Teams {
      &_Table {
        width: 100%;
      }
    }
    &_Users {
      &_Table {
        width: 100%;
      }
    }
  }

  ._darkMode {
    .red {
      color: lighten($hugr-colours-red, 30%);
    }
  }
</style>
