<template>
  <Body :title="$gettext('Compliance & reporting')" class="Compliance">
    <div class="Compliance_Widgets">
      <!-- <PortfoliosWidget /> -->
      <ProjectsWidget :customer="customer"/>
      <TotalIssuesWidget :customer="customer"/>
      <CriticalIssuesWidget :customer="customer"/>
      <IssueDistributionWidget :customer="customer"/>
      <CommonIssuesWidget :customer="customer"/>
      <WorstPerformersWidget :customer="customer"/>
      <AccessibilityStatementsWidget :customer="customer"/>
      <!-- <ChecksConductedWidget /> -->
    </div>
  </Body>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();

  const customer = ref( null );
  if( route.name == 'complianceByCustomer' ) customer.value = route.params.customer;

  // import PortfoliosWidget from '../widgets/Portfolios.vue';
  import ProjectsWidget from '../widgets/Projects.vue';
  import TotalIssuesWidget from '../widgets/TotalIssues.vue';
  import CriticalIssuesWidget from '../widgets/CriticalIssues.vue';
  import IssueDistributionWidget from '../widgets/IssueDistribution.vue';
  import CommonIssuesWidget from '../widgets/CommonIssues.vue';
  import WorstPerformersWidget from '../widgets/WorstPerformers.vue';
  import AccessibilityStatementsWidget from '../widgets/AccessibilityStatements.vue';
  // import ChecksConductedWidget from '../widgets/ChecksConducted.vue';
</script>

<style lang="scss" scoped>
  .Compliance {
    &_Widgets {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;
    }
  }
</style>
