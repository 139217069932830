<template>
  <Widget title="Worst performers" subtitle="Based on the number of issues present" class="WorstPerformersWidget" nominalWidth="4" height="3">
    <template v-if="statistics">
      <div class="WorstPerformersWidget_Item">
        <div class="WorstPerformersWidget_Item_Details">
          <h5>
            <Icon preset="portfolio" />&nbsp;
            <span>Portfolio:</span>
          </h5>
          <p>{{ statistics.worstPerformers.portfolio.title }}</p>
          <p class="WorstPerformersWidget_Item_Details_Count">
            <span class="WorstPerformersWidget_Item_Details_Count_Number">{{ countIssues( statistics.worstPerformers.portfolio.issues ) }}</span>
            <span>&nbsp;issues</span></p>
        </div>
        <SeverityLine
          type="pills"
          :advisory="statistics.worstPerformers.portfolio.issues.advisory"
          :low="statistics.worstPerformers.portfolio.issues.low"
          :medium="statistics.worstPerformers.portfolio.issues.medium"
          :high="statistics.worstPerformers.portfolio.issues.high"
          :critical="statistics.worstPerformers.portfolio.issues.critical"
          />
      </div>
      <div class="WorstPerformersWidget_Item">
        <div class="WorstPerformersWidget_Item_Details">
          <h5>
            <Icon preset="project" />&nbsp;
            <span>{{ $hugrConfig.projectStringCap }}:</span>
          </h5>
          <p><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${statistics.worstPerformers.report.identifier}`" >{{ statistics.worstPerformers.report.title }}</router-link></p>
          <p class="WorstPerformersWidget_Item_Details_Count">
            <span class="WorstPerformersWidget_Item_Details_Count_Number">{{ countIssues( statistics.worstPerformers.report.issues ) }}</span>
            <span>&nbsp;issues</span></p>
        </div>
        <SeverityLine
          type="pills"
          :advisory="statistics.worstPerformers.report.issues.advisory"
          :low="statistics.worstPerformers.report.issues.low"
          :medium="statistics.worstPerformers.report.issues.medium"
          :high="statistics.worstPerformers.report.issues.high"
          :critical="statistics.worstPerformers.report.issues.critical"
          />
      </div>
      <div class="WorstPerformersWidget_Item">
        <div class="WorstPerformersWidget_Item_Details">
          <h5>
            <Icon preset="page" />&nbsp;
            <span>Page:</span>
          </h5>
          <p>{{ statistics.worstPerformers.page.title }}</p>
          <p class="WorstPerformersWidget_Item_Details_Count">
            <span class="WorstPerformersWidget_Item_Details_Count_Number">{{ countIssues( statistics.worstPerformers.page.issues ) }}</span>
            <span>&nbsp;issues</span></p>
        </div>
        <SeverityLine
          type="pills"
          :advisory="statistics.worstPerformers.page.issues.advisory"
          :low="statistics.worstPerformers.page.issues.low"
          :medium="statistics.worstPerformers.page.issues.medium"
          :high="statistics.worstPerformers.page.issues.high"
          :critical="statistics.worstPerformers.page.issues.critical"
          />
      </div>
      <div class="WorstPerformersWidget_Item">
        <div class="WorstPerformersWidget_Item_Details">
          <h5>
            <Icon preset="component" />&nbsp;
            <span>Component:</span>
          </h5>
          <p>{{ statistics.worstPerformers.component.title }}</p>
          <p class="WorstPerformersWidget_Item_Details_Count">
            <span class="WorstPerformersWidget_Item_Details_Count_Number">{{ countIssues( statistics.worstPerformers.component.issues ) }}</span>
            <span>&nbsp;issues</span></p>
        </div>
        <SeverityLine
          type="pills"
          :advisory="statistics.worstPerformers.component.issues.advisory"
          :low="statistics.worstPerformers.component.issues.low"
          :medium="statistics.worstPerformers.component.issues.medium"
          :high="statistics.worstPerformers.component.issues.high"
          :critical="statistics.worstPerformers.component.issues.critical"
          />
      </div>
    </template>
  </Widget>
</template>

<script setup>
  import { ref, toRefs, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import { sum } from 'lodash';

  import SeverityLine from '@/components/UI/SeverityLine';

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      worstPerformers {
        portfolio {
          id
          title
          issues {
            advisory
            low
            medium
            high
            critical
          }
        }

        report {
          id
          title
          identifier
          issues {
            advisory
            low
            medium
            high
            critical
          }
        }

        page {
          id
          title
          issues {
            advisory
            low
            medium
            high
            critical
          }
        }

        component {
          id
          title
          issues {
            advisory
            low
            medium
            high
            critical
          }
        }
      }
    }`;

  const props = defineProps( {
    customer: String,
  } );
  const { customer } = toRefs( props );

  const query = ref( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  if( customer.value ) {
    query.value = gql`
    query Customer( $id: ObjectID! ) {
      me: Customer( id: $id ) {
        _id
        ${statsgql}
      }
    }
  `;
  }

  const { onResult, refetch, onError } = useQuery( query,
    {
      id: customer.value,
    },
  );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const countIssues = issues => sum( Object.values( issues ).filter( i => !isNaN( i ) ) );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_fonts.scss';
  .WorstPerformersWidget {
    &_Item {
      margin: 16px 0;
      display: flex;
      align-items: center;
      &_Details {
        width: 60%;
        h5 {
          font-family: $hugr-fonts-body;
          font-weight: bold;
          font-size: 1em;
          margin: 2px 0;
        }
        p { margin: 2px 0; }
        a { text-decoration: none; }

        &_Count {
          color: $hugr-colours-primary;
          font-size: 0.8em;
          &_Number {
            font-weight: bold;
            color: black;
            font-size: 1.2em;
          }
        }
      }
    }
  }
</style>
