<template>
  <Modal
    ref="modal"
    :title="$gettext('New Issue Instance')"
    :subtitle="identifier"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Issue..."

    :submitText="$gettext('Add issue instance')"
    @submit="submitIssue"

    @close="reset()"
    class="IssueInstanceModal"
    :fix="true">
    <dl v-if="identifier" class="IssueInstanceModal_TemplateDetails">
      <div class="IssueInstanceModal_TemplateDetails_Item">
        <dt>Severity:</dt>
        <dd>
          <Pill
            :text="[ 'Advisory', 'Low', 'Medium', 'High', 'Critical' ][ template.severity ]"
            size="small"
            :tooltip="[
              'Issues that should be addressed to improve user experience',
              'Issues that may have minor impact for some users',
              'Issues that some users may have difficult with',
              'Issues that cause severe difficulties for some users',
              'Issues that mean some users cannot bypass without assistance'
            ][ template.severity ]"
            />
        </dd>
      </div>
      <div class="IssueInstanceModal_TemplateDetails_Item">
        <dt>Priority:</dt>
        <dd><Pill :text="[ 'Lowest', 'Low', 'Medium', 'High', 'Highest' ][ template.severity ]" size="small" /></dd>
      </div>
    </dl>
    <FormFieldSet legend="Issue Details" decoration="none" :hideLegend="true">
      <AriaSearchSelect id="IssueSelect" ref="issueTemplateSelect" :label="$gettext('Issue Template')" :dropdown="true" :gqlOptions="['ISSUETEMPLATES_BY_REPORT', report]" :priority="priorityTemplates" @selected="setIssueTemplate" :validation="['not-empty']"/>

      <FormTextArea ref="reason" idRoot="issue_" :label="$gettext(`Description`)" v-model="reason" :validation="['not-empty']" hintText="Please include a detailed description of the issue and its location on the page."/>
      <Notice size="micro" v-if="reason==''&&presetReasonText!=''">
        <span>{{ presetReasonText }}
        <Button size="micro" @click="() => { this.reason = this.presetReasonText; }">{{$gettext('Use this')}}</Button>
      </span></Notice>

      <!-- <template v-if="template">
        <div v-if="template.reasonTemplates&&template.reasonTemplates.filter(t => t!='').length">
          <h5 v-translate>Reason Templates</h5>
          <ul>
            <template v-for="(reasonTemplate,index) in template.reasonTemplates">
              <li v-if="reasonTemplate && reasonTemplate!='' && reasonToBeReplacedWith != reasonTemplate" v-bind:key="'reasonTemplate-' + index">{{reasonTemplate}}
              <Button class="buttonPadRight" size="micro" v-if="reason==''" @click.prevent="populateReason(reasonTemplate)">{{$gettext('Use')}}</Button>
              <Button class="buttonPadRight" size="micro" v-if="reason!=''" @click.prevent="reasonToBeReplacedWith = reasonTemplate">{{$gettext('Replace')}}</Button>
              <Button class="buttonPadRight" size="micro" v-if="reason!=''" @click.prevent="populateReason(`${reason} ${reasonTemplate}`)">{{$gettext('Append')}}</Button>
              </li>
              <li v-if="reasonTemplate && reasonTemplate!='' && reasonToBeReplacedWith == reasonTemplate" v-bind:key="'reasonTemplate-' + index">
              <span>Are you certain you want to replace the entire issue description with this reason template? </span>
              <Button class="buttonPadRight" size="micro" v-if="reason!=''" @click.prevent="populateReason(reasonTemplate); reasonToBeReplacedWith = ''">{{$gettext('Confirm Replace')}}</Button>
              <Button class="buttonPadRight" size="micro" v-if="reason!=''" @click.prevent="reasonToBeReplacedWith = ''">{{$gettext('Cancel')}}</Button>
              </li>
            </template>
          </ul>
        </div>
      </template> -->
      <!-- <FormInput idRoot="issue_" :label="$gettext('Instance Identifier')" v-model="identifier" disabled/> -->

      <div class="IssueInstanceModal_Location">
        <TabList v-if="!hideTabs" class="IssueInstanceModal_Location_TabList" :lastIndex="lastKnownTab" @tabChanged="lastKnownTab = $event;" :tabs="tabs"/>
        <div class="Components_Tabs_Pane" id="tabpane" ref="tabpane">
          <AriaSearchSelect v-show="tabbedToPage" ref="pageselect" :label="$gettext('Page')" :dropdown="true" :multiple="true" idRoot="issue_" :gqlOptions="['PAGES_BY_REPORT', report]" @selected="setPages" :default="page" :validation="['not-empty']"/>

          <AriaSearchSelect v-show="!tabbedToPage" ref="componentselect" :label="$gettext('Component')" :dropdown="true" idRoot="issue_" :gqlOptions="['COMPONENTS_BY_REPORT', report]" @selected="setComponent" :default="component" :validation="['not-empty','valid-option', 'option-matches']"/>
        </div>
      </div>
    </FormFieldSet>

    <FormFieldSet legend="Additional Info" decoration="none" :hideLegend="true">
      <AriaSearchSelect ref="platformselect" :label="$gettext('Platform')" :dropdown="true" idRoot="platform_" @selected="setPlatforms" :validation="['not-empty']" :options="[
        { value: 0, display: $gettext('Web - Any') },
        { value: 1, display: $gettext('Web - Firefox') },
        { value: 2, display: $gettext('Web - Chrome') },
        { value: 3, display: $gettext('Web - IE') },
        { value: 4, display: $gettext('Web - Edge') },
        { value: 5, display: $gettext('Web - Other') },
        { value: 6, display: $gettext('Mobile - Android') },
        { value: 7, display: $gettext('Mobile - iOS') },
        { value: 8, display: $gettext('Mobile - Any') },
        { value: 9, display: $gettext('Mobile Web - Android / Chrome') },
        { value: 10, display: $gettext('Mobile Web - iOS / Safari') }
    ]" default="0"/>

      <!-- <AriaSearchSelectList v-if="solutions.length" v-model:items="solutions"/>
      <Empty v-else :text="$gettext('No solutions, add one')" type="small" /> -->

      <AriaSearchSelect ref="solutionselect" :label="$gettext('Add a solution')" :dropdown="true" :multiple="true" idRoot="issue_" :gqlOptions="['SOLUTIONS']" @selected="setSolutions" :priority="template.solutions.map(solution => solution._id)"/>

      <div class="IssueInstanceModal_Images">
        <h3>Images</h3>
        <Gallery v-model="evidence" :editable="true" />
      </div>
    </FormFieldSet>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

// import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaSearchSelect from '@/components/Aria/SearchSelect3';
// import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

import FormFieldSet from '@/components/Form/FieldSet';

// import FormInput from '@/components/Form/Input';
// import FormSelect from '@/components/Form/Select';
import UIGallery from '@/components/UI/Gallery';
import FormTextArea from '@/components/Form/TextArea';

import Pill from '@/components/UI/Pill.vue';

export default {
  name: 'IssueInstanceModal',
  data() {
    const tabs = [
      {
        text: "Page",
        controls: "tabpane",
        action: () => {
          if( !this.tabbedToPage ) {
            this.tabbedToPage = true;
          }
        },
      },
      {
        text: "Component",
        controls: "tabpane",
        action: () => {
          if( this.tabbedToPage ) {
            this.tabbedToPage = false;
          }
        },
      },
    ];

    return {
      tabbedToPage: true,
      lastKnownTab: 0,
      tabs,
      issueTemplate: '',
      identifier: '',
      page: '0',
      pages: [],
      component: '0',
      reason: '',
      evidence: [],
      solutions: [],
      status: 'reported',
      platform: 0,
      issueTemplateSearchPage: 0,
      issueTemplatePageSize: 5,
      searchTemplates: false,
      presetReasonText: "",
      reasonToBeReplacedWith: '',
      multiplePages: false,
      hideTabs: false,

      template: {
        solutions: [],
      },

      submitting: false,

      row: false,
      priorityTemplates: [],
    };
  },
  watch: {
    reason() {
      this.setSearchTemplates();
    },
    // multiplePages() {
    //   if( this.multiplePages == false && this.pages.length ) {
    //     setTimeout( () => {
    //       //set first as selected
    //       this.$refs.pageselect.selectWithoutFocus( this.pages[0]._id, this.pages[0].name );
    //       this.page = this.pages[0]._id;
    //       //clear pages
    //       this.pages = [];
    //     }, 100 );
    //   } else if( this.multiplePages == true && this.page != '0' ) {
    //     //set selected as first
    //     this.addPage( this.page );
    //   }
    // },
  },
  methods: {
    setSearchTemplates() {
      clearTimeout( this.typingTimeout );
      let useReason = this.reason;
      if( this.reason == '' ) {
        useReason = this.presetReasonText;
      }
      if( useReason == '' ) {
        this.searchTemplates = false;
      } else {
        this.typingTimeout = setTimeout( () => {
          this.$apollo.query( {
            query: gql`
              query SearchIssueTemplates($term: String!) {
                templates: SearchIssueTemplates(term: $term) {
                  _id,
                  identifier,
                  title
                }
              }
            `,
            variables: {
              term: useReason,
            },
          } ).then( res => {
            this.issueTemplateSearchPage = 0;
            this.searchTemplates = res.data.templates;
          } ).catch( () => {
            this.$alerts.coded( 'E071', 'F4201' ); //see notifications spreadsheet
          } );
        }, 1000 );
      }
    },
    nextIssueTemplates() {
      this.issueTemplateSearchPage++;
      if ( ( this.issueTemplateSearchPage * this.issueTemplatePageSize ) >= this.searchTemplates.length ) {
        this.issueTemplateSearchPage = 0;
      }
    },
    setRow( row ) {
      this.row = row;
      this.$apollo.query( {
        query: gql`
          query IssueTemplatesFromRow($row: ObjectID!) {
            templates: IssueTemplatesFromRow(row: $row) {
              _id
            }
          }
        `,
        variables: {
          row,
        },
      } ).then( ( { data } ) => {
        this.priorityTemplates = data.templates.map( template => template._id );
      } );
    },
    setIssueTemplate( issueTemplate, display ) {
      this.$refs.issueTemplateSelect.select( issueTemplate, display );
      this.issueTemplate = issueTemplate;

      this.$apollo.query( {
        query: gql`
          query newInstanceIdentifier($rv: ObjectID!, $tmp: ObjectID!) {
            identifier: newInstanceIdentifier(reportVersion: $rv, template: $tmp)
          }
        `,
        variables: {
          rv: this.report,
          tmp: issueTemplate,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.identifier = res.data.identifier;
      } ).catch( () => {
        this.$alerts.coded( 'E072', 'F4202' ); //see notifications spreadsheet
      } );

      //get template for priority Solutions
      this.$apollo.query( {
        query: gql`
          query IssueTemplate($id: ObjectID!) {
            template: IssueTemplate(id: $id) {
              reasonTemplates
              severity
              solutions {
                _id
              }
            }
          }
        `,
        variables: {
          id: issueTemplate,
        },
      } ).then( res => {
        this.template = res.data.template;
      } ).catch( () => {
        this.$alerts.coded( 'E059', 'F4203' ); //see notifications spreadsheet
      } );
    },
    presetReason( text ) {
      this.presetReasonText = text;
      this.setSearchTemplates();
    },
    presetPage( page ) {
      this.page = page;
      this.lastKnownTab = 0;
      this.tabbedToPage = true;
      this.hideTabs = true;
    },
    setPages( pages ) {
      if( pages.length == 1 ) {
        this.pages = [];
        this.multiplePages = false;
        [ this.page ] = pages;
      } else {
        [ this.page ] = pages;
        this.multiplePages = true;
        this.pages = pages;
      }
    },
    presetComponent( component ) {
      this.component = component;
      this.lastKnownTab = 1;
      this.tabbedToPage = false;
      this.hideTabs = true;
    },
    setComponent( component, display ) {
      this.$refs.componentselect.select( component, display );
      this.component = component;

    },
    setSolutions( solutions ) {
      this.solutions = solutions;
    },
    populateReason( text ) {
      this.reason = text;
      this.$refs.reason.focus();
    },
    submitIssue() {
      const validations = [ 'issueTemplateSelect', 'reason' ];
      if( this.tabbedToPage && !this.multiplePages ) {
        validations.push( 'pageselect' );
      } else if( !this.tabbedToPage ) {
        validations.push( 'componentselect' );
      }

      let forceFail = false;
      if( this.tabbedToPage && this.multiplePages && this.pages.length == 0 ) {
        this.$refs.pageselectmulti.addError( 'Please select atleast one page' );
        forceFail = true;
      } else if( this.tabbedToPage && this.multiplePages ) {
        // this.$refs.pageselectmulti.reset();
      }

      this.$hugrvalidate( validations, this, { pageselect: this.page, componentselect: this.component }, forceFail ).then( ( { success } ) => {
        if( success ) {
          // let canDo = true;
          this.submitting = true;
          let evidenceResult = null;

          if ( this.evidence.length > 0 ) {
            evidenceResult = this.evidence;
          }

          const instance = {
            reporter: this.user.id,
            reportVersion: this.report,
            identifier: this.identifier,
            issueTemplate: this.issueTemplate,
            reason: this.reason,
            confidence: 100,
            isManual: true,
            evidence: evidenceResult,
            solutions: this.solutions,
            status: this.status,
            platform: this.platform,
          };
          if( this.page && this.page != "0" && this.tabbedToPage && !this.multiplePages ) {
            instance.page = this.page;
          } else if( this.tabbedToPage && this.multiplePages && this.pages.length ) {
            [ instance.page ] = this.pages;
            instance.additionalPages = this.pages.filter( p => p != instance.page );
          }
          if( this.component && this.component != "0" && !this.tabbedToPage ) {
            instance.component = this.component;
          }

          this.$apollo.mutate( {
            mutation: gql`
              mutation addIssueInstance($instance: IssueInstanceInput!) {
                instance: addIssueInstance(instance: $instance) {
                  _id,
                  identifier
                  template {
                    _id
                    severity
                  }
                }
              }
            `,
            variables: {
              instance,
            },
          } ).then( res => {
            this.submitting = false;
            this.$alerts.success( 'Issue instance added', `The issue instance ${this.identifier} has been added.` );
            this.$emit( 'success', {
              id: res.data.instance._id,
              identifier: res.data.instance.identifier,
              severity: res.data.instance.template.severity,
            } );
            this.reset();
          } ).catch( () => {
            this.submitting = false;
            this.$alerts.coded( 'E073', 'F4205' ); //see notifications spreadsheet
          } );
        }
      } );
    },

    show() {
      this.clear();
      this.$refs.modal.show();
    },
    clear() {
      this.identifier = '';
      this.issueTemplate = '';
      this.tabbedToPage = true;
      this.lastKnownTab = 0;
      this.page = '0';
      this.pages = [];
      this.component = '0';
      this.reason = '';
      this.evidence = [];
      this.solutions = [];
      this.status = 'reported';
      this.platform = 0;
      this.multiplePages = false;
      this.row = false;
      this.priorityTemplates = [];

      this.template = {
        solutions: [],
      };
    },
    reset() {
      this.clear();

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    searchTemplatesSlice() {
      if ( this.searchTemplates.length >= ( this.issueTemplateSearchPage + 1 ) * this.issueTemplatePageSize ) {
        return this.searchTemplates.slice( this.issueTemplateSearchPage * this.issueTemplatePageSize, ( this.issueTemplateSearchPage + 1 ) * this.issueTemplatePageSize );
      }

        return this.searchTemplates.slice( this.issueTemplateSearchPage * this.issueTemplatePageSize, this.searchTemplates.length );

    },
  },
  props: {
    report: {
      type: String,
      required: true,
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    FormFieldSet,
    // FormInput,
    // FormSelect,
    Gallery: UIGallery,
    FormTextArea,
    AriaSearchSelect,
    // AriaSearchSelectList,
    Pill,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.IssueInstanceModal {

  &_TemplateDetails {
    position: fixed;
    top: 6vh;
    right: 26vw;
    text-align: right;
    &_Item {
      margin-bottom: 8px;
      dt {
        font-weight: bold;
        display: inline-block;
        // vertical-align: top;
        font-size: 0.9em;
      }
      dd {
        display: inline-block;
        // vertical-align: top;
        margin-left: 8px;
      }
    }
  }

  &_Location {
    position: relative;
    &_TabList {
      padding-bottom: 10px !important;
    }
    &_MultiSelect {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  &_Images {
    h3 {
      font-family: "fs-me-regular", sans-serif;
      font-weight: bold;
      font-size: 0.9em;
      margin: 0;
      margin-bottom: 4px;
    }
  }
}

.actions {
  margin-top: 10px
}

.buttonPadRight {
  margin-right: 5px;
}

.SuggestedTemplates {
  &_Container {
    border: 1px solid $hugr-colours-grey;
    border-radius: 3px;
    margin-bottom: 10px;
    &_List {
      list-style: none;
      margin: 0;
      padding: 0;
      &_Item {
        border-bottom: 1px solid $hugr-colours-grey;
        // &:last-child {
        //   border-bottom: none;
        // }

        &._nonButton {
          padding: 5px;
          font-size: 0.9em;
        }

        button {
          width: 100%;
          background: none;
          border: 1px solid  transparent;
          padding: 5px;
          text-align: left;
          cursor: pointer;

          &:focus, &:hover, &._active {
            // border: 1px solid $hugr-colours-tertiary;
            background: $hugr-colours-grey;
          }
        }
      }
    }
    &_Button {
      width: 100%;
      text-align: center;
    }
  }
}

._darkMode .SuggestedTemplates {
  &_Container {
    &_List {
      &_Item {
        button {
          color: $hugr-colours-grey;
          &:focus, &:hover, &._active {
            background: darken($hugr-colours-primary, 10%);
          }
        }
      }
    }
  }
}
</style>
