<template>
  <div>

    <Body :title="$gettext(`My Portfolios`)" :fill="true" v-if="me && !$apollo.queries.portfolio.loading && me.portfolios?.length > 0" class="MyPortfolio">
      <LinkButton type="secondary" size="small" class="MyPortfolio_ShowAll" :to="`/myportfolios`">Show all</LinkButton>
      <TabList v-if="tabs.length" :tabs="tabs" :key="`teamtabs-${tabs.length}`"
        :lastIndex="tabs.map(t => t.text).indexOf(portfolio.name)" />
      <div id="portfolio" v-if="portfolio">
        <h3>{{ portfolio.name }}</h3>

        <ButtonSet class="MyPortfolio_Opts" label="Options" :dropdown="true" size="small" type="secondary">
          <Button size="small" type="transparent" @click="$refs.csvportfoliomodal.show(portfolio._id)">{{$gettext('Export as CSV')}}</Button>
        </ButtonSet>

        <CardSet class="MyPortfolio_Reports" :title="$gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } )" v-if="!hasPermission('Restrictions','CanNotCreateProducts')"
          hlevel="3"
          :button="{icon:['solid', 'folder-plus', 'after'], text: $gettext( 'New %{projectString}', { projectString: $hugrConfig.projectString } )}"
          @buttonClick="$refs.reportmodal.show()">
          <div v-if="portfolio.reports.length">
            <DataTable
              class="MyPortfolio_Reports_Table"
              :headers="[
                'Title',
                'URL',
                $gettext( '%{projectString} Risk', { projectString: $hugrConfig.projectStringCap } ),
                'Disproportionate Burden',
                'Statement Compliance',
                'Actual Compliance',
                'Progress'
              ]"
            >
              <tr
                v-for="(report, key) in portfolio.reports"
                :key="`${report.identifier}-${key}`"
              >
                <td class="MyPortfolio_Reports_Table_Title">
                  <router-link class="Reports_Link" :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`">
                    {{ report.title }}
                  </router-link>
                </td>
                <td>{{ report.productUrl }}</td>
                <td>{{ riskString(report.productRisk) }}</td>
                <td>{{ disproportionateBurdenString(report.disproportionateBurden) }}</td>
                <td>{{ accessibilityStatementString(report.accessibilityStatement) }}</td>
                <td>{{ actualComplianceString(report.currentVersion.isCompliant, report.currentVersion.hasOpenIssues, report.currentVersion.progress) }}</td>
                <td><ProgressBar :id="`reportprogress-${report._id}`" :label="$gettext( '%{projectString} Progress', { projectString: $hugrConfig.projectStringCap } )" :hideLabel="true" :value="report.currentVersion.progress"/></td>
              </tr>
            </DataTable>
          </div>
          <Empty v-else-if="!hasPermission('Restrictions','CanNotCreateProducts')" :text="$gettext('Nothing here!')" :button="{icon:['solid', 'folder-plus'], text: gettext( 'Add a %{projectString}', { projectString: $hugrConfig.projectStringCap } )}"
            @buttonClick="$refs.reportmodal.show()" />
          <Empty v-else :text="$gettext('Nothing here!')" />
        </CardSet>
        <CardSet class="MyPortfolio_Reports" :title="$gettext('Products')" v-else
          hlevel="3">
          <div v-if="portfolio.reports.length">
            <DataTable
              class="MyPortfolio_Reports_Table"
              :headers="[
                'Title',
                'URL',
                'Product Risk',
                'Disproportionate Burden',
                'Statement Compliance',
                'Actual Compliance',
                'Progress'
              ]"
            >
              <tr
                v-for="(report, key) in portfolio.reports"
                :key="`${report.identifier}-${key}`"
              >
                <td class="MyPortfolio_Reports_Table_Title">
                  <router-link class="Reports_Link" :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`">
                    {{ report.title }}
                  </router-link>
                </td>
                <td>{{ report.productUrl }}</td>
                <td>{{ riskString(report.productRisk) }}</td>
                <td>{{ disproportionateBurdenString(report.disproportionateBurden) }}</td>
                <td>{{ accessibilityStatementString(report.accessibilityStatement) }}</td>
                <td>{{ actualComplianceString(report.currentVersion.isCompliant, report.currentVersion.hasOpenIssues, report.currentVersion.progress) }}</td>
                <td><ProgressBar :id="`reportprogress-${report._id}`" :label="$gettext( '%{projectString} Progress', { projectString: $hugrConfig.projectStringCap } )" :hideLabel="true" :value="report.currentVersion.progress"/></td>
              </tr>
            </DataTable>
          </div>
          <Empty v-else-if="!hasPermission('Restrictions','CanNotCreateProducts')" :text="$gettext('Nothing here!')" :button="{icon:['solid', 'folder-plus'], text: gettext( 'Add a %{projectString}', { projectString: $hugrConfig.projectStringCap } )}"
            @buttonClick="$refs.reportmodal.show()" />
          <Empty v-else :text="$gettext('Nothing here!')" />
        </CardSet>

        <h3 v-translate>Activity Log</h3>
        <Comments v-if="portfolio.teams[0]" :reference="portfolio._id" :team="portfolio.teams[0]._id" :fileUploadEnabled="hasPermission('Files','Upload')"/>
        <Comments v-else :reference="portfolio._id" :fileUploadEnabled="hasPermission('Files','Upload')"/>

        <ReportModal ref="reportmodal" @success="$apollo.queries.portfolio.refetch()" :portfolioId="portfolio._id" />
        <CsvPortfolioModal ref="csvportfoliomodal" />
      </div>
      <div v-else>
        {{$gettext( 'Couldn\'t fetch portfolio with identifier %{portfolioIdentifier}, are you sure you have access?', { portfolioIdentifier: tident } ) }}
      </div>
    </Body>
    <Body :title="$apollo.queries.portfolio.loading ? $gettext('Loading') : $gettext('No portfolios')" :fill="true" v-else>
      <div v-if="$apollo.queries.portfolio.loading">
        <Loader />
      </div>
      <div v-else>
        {{$gettext("You don't have any portfolios that you are a principal of or you do not belong to any teams which are assigned to a portfolio.")}}
      </div>
    </Body>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';

import Comments from '@/components/Comments/Index';
import ReportModal from '@/modals/Report/Create';
import CsvPortfolioModal from '@/modals/CSV/Portfolio';

import ProgressBar from '@/components/UI/ProgressBar';

export default {
  name: 'MyPortfolioView',
  mounted() {
    this.$apollo.queries.me.refetch();

    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'My Portfolio',
      },
    } );
  },
  data() {
    return {
      tident: this.$route.params.identifier.replace( '%20', ' ' ),
      me: false,
      portfolio: false,
      tabs: [],
    };
  },
  watch: {
    $route() {
      if( this.$route.name == 'myportfolio' ) {
        this.tident = this.$route.params.identifier.replace( '%20', ' ' );
        this.$apollo.queries.portfolio.refetch();
      }
    },
    me() {
      this.tabs = [];
      if( this.me.portfolios.length >= 1 && this.tident == 'my' ) {
        this.$router.push( `/portfolio/${this.me.portfolios[0].identifier}` );
      }
      if( this.me.portfolios.length > 1 && this.tident != 'none' ) {
        for( const portfolio of this.me.portfolios ) {
          if( portfolio.name && portfolio.identifier ) {
            this.tabs.push( {
              text: portfolio.name,
              controls: "portfolio",
              action: () => {
                this.$router.push( `/portfolio/${portfolio.identifier}` );
              },
            } );
          }
        }
      } //else {
      //   this.tident = "none";
      //   this.portfolio = false;
      //   this.$router.push( `/portfolio/none` );
      // }
    },
  },
  apollo: {
    me: {
      query: gql`
        {
          me: WhoAmI {
            _id,
            email,
            name,
            aclevel,
            mfaEnabled,
            portfolios {
              _id
              identifier
              name
            }
          }
        }
      `,
    },
    portfolio: {
      query: gql`
        query Portfolio($ident: String) {
          portfolio: Portfolio(identifier: $ident) {
            _id
            identifier
            name
            reports {
              _id
              identifier
              title
              productUrl,

              productRisk,
              disproportionateBurden,
              accessibilityStatement,

              currentVersion {
                _id
                isCompliant
                hasOpenIssues

                progress
              }
            }
            teams {
              _id
            }
          }
        }
      `,
      variables() {
        return {
          ident: this.tident,
        };
      },
      skip() {
        return this.tident == 'none' || this.tident == 'my';
      },
    },

  },
  methods: {
    riskString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'low';

          break;
        }
        case '1': {
          ret = 'medium';

          break;
        }
        case '2': {
          ret = 'high';

          break;
        }
      }

      return this.$gettext( ret );
    },
    disproportionateBurdenString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'none';

          break;
        }
        case '1': {
          ret = 'being considered';

          break;
        }
        case '2': {
          ret = 'made';

          break;
        }
        case '3': {
          ret = 'expired';

          break;
        }
      }

      return this.$gettext( ret );
    },
    accessibilityStatementString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'Unknown';

          break;
        }
        case '1': {
          ret = 'partially - mostly unmet';

          break;
        }
        case '2': {
          ret = 'partally - mostly met';

          break;
        }
        case '3': {
          ret = 'met';

          break;
        }
        case '4': {
          ret = 'exceeded';

          break;
        }
      }

      return this.$gettext( ret );
    },
    actualComplianceString( isCompliant, hasOpenIssues, progress ) {
      if( progress == 0 ) return 'Untested';

      if( isCompliant && progress < 100 ) return 'Partially tested';

      let ret = '';
      if( isCompliant ) {
        ret = 'Is Compliant';
      } else {
        ret = 'Not Compliant';
      }

      if( isCompliant && hasOpenIssues ) ret += ' with advisories';

      if( progress < 100 ) ret += ', Partially tested';
      
      return ret;
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
  },
  components: {
    Comments,
    ReportModal,
    CsvPortfolioModal,
    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

.MyPortfolio {
  position: relative;

  &_ShowAll {
    float: right;
    margin-top: -50px;
  }

  &_Opts {
    position: absolute;
    margin-top: -48px;
    right: 50px;
  }
  // &_Members {
  //   &_Table {
  //     width: 100%;
  //   }
  // }
}
</style>
